import { useQuery, UseQueryOptions } from '@tanstack/react-query';
import { useSelector } from 'react-redux';
import { useParams } from 'react-router';

import { getCurrentBrand } from 'src/Redux/Perimeter/Selectors';
import { CandidatesService, CandidateWeeklyAvailabilities } from 'src/Services/API';

import { QueryKeys } from '../types';

export const useFetchCandidateWeeklyAvailabilitiesFromURL = (
  extraOptions?: UseQueryOptions<CandidateWeeklyAvailabilities, unknown>
) => {
  const { candidateId } = useParams<{ candidateId: string }>();
  const brand = useSelector(getCurrentBrand);

  return useQuery<CandidateWeeklyAvailabilities, unknown>(
    [QueryKeys.fetchCandidateWeeklyAvailabilities, candidateId, brand.brandCodeApiHeader],
    async () => {
      return await CandidatesService.candidatesControllerGetCandidateWeeklyAvailabilities({
        id: candidateId,
        brandCode: brand.brandCodeApiHeader,
      });
    },
    extraOptions
  );
};

import { ModalContent } from '@randstad-lean-mobile-factory/react-components-core';

import { HeaderMetier } from 'src/Assets';
import { pluralFormat } from 'src/Utils/pluralFormat';

import styles from './CandidateBusinessModal.module.scss';
import { Props } from './CandidateBusinessModal.types';

const CandidateBusinessModal = ({ item: business }: Props) => {
  const qualifications = business.candidateQualifications ?? [];
  const businessSkills = business.candidateBusinessesSkills ?? [];

  return (
    <ModalContent header={<HeaderMetier />} title={business.label}>
      <div>
        {qualifications.length !== 0 && (
          <div className={styles.subtitle}>
            {`${pluralFormat(qualifications.length ?? 0, 'qualification acquise')} pour ce métier`}
          </div>
        )}
        {qualifications.map(qualification => (
          <li className={styles.listElement}>{qualification.label}</li>
        ))}
        {businessSkills.length !== 0 && (
          <div className={styles.subtitle}>{`${pluralFormat(
            businessSkills.length,
            'compétence acquise'
          )} pour ce métier`}</div>
        )}
        {businessSkills.map(businessSkill => (
          <li className={styles.listElement}>{businessSkill.label}</li>
        ))}
      </div>
    </ModalContent>
  );
};

export default CandidateBusinessModal;

import React from 'react';
import ReactDOM from 'react-dom';

import 'reflect-metadata';
import './index.scss';
import './variables.scss';
import Providers from 'src/Providers';

import { App } from './App';

import './bootstrap';

ReactDOM.render(
  <React.StrictMode>
    <Providers>
      <App />
    </Providers>
  </React.StrictMode>,
  document.getElementById('root')
);

import { Route, Router, Switch, useHistory, useParams } from 'react-router';

import { CandidateRoute } from 'src/Services/Routing';

import { navigation } from '../CandidateFile.types';

import styles from './CandidateFileForm.module.scss';

const CandidateFileForm = () => {
  const params = useParams<CandidateRoute['routerParameters']>();
  const history = useHistory();

  const firstSection = params?.firstSection ?? '';

  const subRoute = navigation[firstSection]?.routes.find(
    _ => _.linkTo === `./${params?.secondSection}`
  );

  const subRouteIndex = navigation[firstSection]?.routes.findIndex(
    _ => _.linkTo === `./${params?.secondSection}`
  );

  return (
    <div className={styles.container}>
      <div className={styles.routeNumber}>{`${subRouteIndex + 1}.`}</div>
      <div className={styles.formContainer}>
        <div className={styles.titleContainer}>
          <p>{subRoute?.name ?? 'Error'}</p>
        </div>
        <div className={styles.content}>
          <Router history={history}>
            <Switch>
              {Object.values(navigation).map(node =>
                node.routes.map(route => {
                  const Component = route.component;
                  return (
                    <Route key={route.name} path={route.path} exact>
                      {Component && <Component />}
                    </Route>
                  );
                })
              )}
            </Switch>
          </Router>
        </div>
      </div>
    </div>
  );
};

export default CandidateFileForm;

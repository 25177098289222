import { AutoSuggest, WithLightTitle } from '@randstad-lean-mobile-factory/react-components-core';
import { throttle } from 'lodash-es';
import React, { useCallback, useState } from 'react';

import { useFetchSkills } from 'src/Hooks/Skills';
import { MatchingCriteriaItemWithLabel } from 'src/Services/API';
import { toFetchStatus } from 'src/Services/ReactQuery';

import { Props } from './SelectSkills.types';

const SelectSkills = ({ selectedSkills, setSkills, customTitle }: Props) => {
  const [keyword, setKeyword] = useState('');
  const mutation = useFetchSkills();

  // eslint-disable-next-line react-hooks/exhaustive-deps
  const throttledMethod = useCallback(throttle(mutation.mutate, 500), [mutation.mutate]);
  return (
    <WithLightTitle
      title={
        customTitle ||
        `${keyword !== '' && mutation.data ? mutation.data.length : '-'} compétences disponibles`
      }
    >
      <AutoSuggest
        id="compétences"
        value={selectedSkills ?? []}
        keyValueExtractor={(searchResult: MatchingCriteriaItemWithLabel) => ({
          key: searchResult.identifier,
          value: searchResult.label ?? '',
        })}
        onSearch={(word: string) => {
          word !== '' && throttledMethod(word);
          setKeyword(word);
        }}
        onChange={skills => {
          setSkills([...skills]);
          setKeyword('');
        }}
        searchResults={
          keyword !== ''
            ? mutation.data?.filter(
                hardSkill =>
                  !selectedSkills?.some(
                    candidateHardSkill => candidateHardSkill.identifier === hardSkill.identifier
                  )
              ) ?? []
            : []
        }
        placeholder="1 caractère minimum"
        fetchStatus={toFetchStatus(mutation)}
      />
    </WithLightTitle>
  );
};

export default SelectSkills;

import { AxiosRequestConfig } from 'axios';

export const downloadFile = (params: { url: string; filename?: string }) => {
  const downloadLink = document.createElement('a');
  downloadLink.href = params.url;
  downloadLink.target = '_blank';
  if (params.filename) downloadLink.download = params.filename;

  document.body.appendChild(downloadLink);
  downloadLink.click();
  document.body.removeChild(downloadLink);
};

export const fetchFile = async <
  P extends {},
  Method extends (params: P, options: AxiosRequestConfig) => Promise<unknown>
>(
  method: Method,
  params: P
) => {
  let filename: string | undefined;
  let type: string | undefined;
  const buffer = (await method(params, {
    responseType: 'arraybuffer',
    onDownloadProgress(event) {
      type = (event.currentTarget as XMLHttpRequest).getResponseHeader('content-type') ?? undefined;
      filename = getFilename(
        (event.currentTarget as XMLHttpRequest).getResponseHeader('content-disposition')
      );
    },
  })) as Buffer;
  type = type ?? 'application/octet-stream';
  const url = URL.createObjectURL(new Blob([buffer], { type }));
  return { url, buffer, type, filename };
};

const getFilename = (disposition: string | null): string | undefined => {
  if (!disposition) return;
  const utf8FilenameRegex = /filename\*=UTF-8''([\w%\-\\.]+)(?:; ?|$)/i;
  const asciiFilenameRegex = /^filename=(["']?)(.*?[^\\])\1(?:; ?|$)/i;

  const matches = utf8FilenameRegex.exec(disposition);
  if (matches) {
    return decodeURIComponent(matches[1]);
  } else {
    const filenameStart = disposition.toLowerCase().indexOf('filename=');
    if (filenameStart >= 0) {
      const partialDisposition = disposition.slice(filenameStart);
      const matches = asciiFilenameRegex.exec(partialDisposition);
      if (matches != null && matches[2]) {
        return matches[2];
      }
    }
  }
};

import {
  Calendar,
  DocumentPdf,
  Dustbin,
  Flux,
  HandUp,
  Shortcuts,
  Undo,
} from '@randstad-lean-mobile-factory/react-assets/dist/icons';
import { Menu, MenuItem } from '@randstad-lean-mobile-factory/react-components-core';
import moment from 'moment';
import { useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useHistory } from 'react-router';

import DeleteR1Modal from 'src/Containers/Modals/DeleteR1Modal';
import ExemptionValidationModal from 'src/Containers/Modals/ExemptionValidationModal';
import InterviewClosedByTTModal from 'src/Containers/Modals/InterviewClosedByTTModal';
import R2PlanningModal from 'src/Containers/Modals/R2PlanningModal';
import { useDownloadR1Pdf } from 'src/Hooks/CandidateR1/useDownloadR1Pdf';
import { useCreateCandidateR2 } from 'src/Hooks/CandidateR2/useCreateCandidateR2';
import { useDownloadR2Pdf } from 'src/Hooks/CandidateR2/useDownloadR2Pdf';
import { useCreateCandidateR1 } from 'src/Hooks/Candidates/useCreateCandidateR1';
import { useResetCandidateInterviews } from 'src/Hooks/Interviews/useResetCandidateInterviews';
import { useOpenCandidateResume } from 'src/Hooks/Navigation/useOpenCandidateResume';
import { getHasUsedResumeGenerator } from 'src/Redux/Features/Selector';
import { featuresActions } from 'src/Redux/Features/Slice';
import { getPerimeterUnits } from 'src/Redux/Perimeter/Selectors';
import { CandidateInterviewsSummaryStatus } from 'src/Services/API';
import { getR1CandidateRoute, getR2CandidateRoute } from 'src/Services/Routing';

import R1ReopenModal from '../R1ReopenModal';

import styles from './DetailButton.module.scss';
import { Props } from './DetailButton.types';

const DetailButton = ({
  candidateInterviewsIdAndStatus,
  candidateId,
  candidateType,
  showReassignCGC,
  showResumeGenerator,
  ...props
}: Props) => {
  const {
    status,
    r1Interview,
    r2Interview,
    agencyId: interviewsAgencyId,
    cgcId: interviewsCgcId,
  } = candidateInterviewsIdAndStatus ?? {};
  const history = useHistory();
  const [hasOpenR1ReopeningModal, showR1ReopeningModal] = useState(false);
  const [hasOpenClosedByTTModal, showCloseByTTModal] = useState(false);
  const [hasOpenForceValidationModal, showForceValidationModal] = useState(false);
  const [deleteR1, setDeleteR1] = useState(false);
  const [hasOpenR2PlanningModal, showR2PlanningModal] = useState(false);
  const units = useSelector(getPerimeterUnits);
  const downloadR1InterviewPdf = useDownloadR1Pdf({
    onSettled: () => {
      setTimeout(() => downloadR1InterviewPdf.reset(), 500);
    },
  });
  const downloadR2InterviewPdf = useDownloadR2Pdf({
    onSettled: () => {
      setTimeout(() => downloadR2InterviewPdf.reset(), 500);
    },
  });
  const createR1Mutation = useCreateCandidateR1(candidateId, {
    onError: () => {
      setTimeout(() => createR1Mutation.reset(), 500);
    },
    onSuccess: data => {
      history.push(
        getR1CandidateRoute({
          candidateId: data.candidateId,
          R1Id: data.R1Id,
        }),
        { origin: history.location.pathname }
      );
    },
  });
  const createR2Mutation = useCreateCandidateR2(candidateId, {
    onError: () => {
      setTimeout(() => createR2Mutation.reset(), 500);
    },
    onSuccess: data => {
      history.push(getR2CandidateRoute({ candidateId: data.candidateId, R2Id: data.R2Id }), {
        origin: history.location.pathname,
      });
    },
  });
  const resetInterviewsMutation = useResetCandidateInterviews(candidateId, {
    onError: () => {
      setTimeout(() => resetInterviewsMutation.reset(), 500);
    },
    onSuccess: data => {
      history.push(
        getR1CandidateRoute({
          candidateId: data.candidateId,
          R1Id: data.R1Id,
        }),
        { origin: history.location.pathname }
      );
    },
  });
  const { openCandidateResume } = useOpenCandidateResume();

  const dispatch = useDispatch();
  const hasUsedResumeGenerator = useSelector(getHasUsedResumeGenerator);

  const isFromInterviewsAgency = interviewsAgencyId && units.includes(interviewsAgencyId);
  const isFromInterviewsCGC = interviewsCgcId && units.includes(interviewsCgcId);

  return (
    <>
      <Menu {...props}>
        {showResumeGenerator && (
          <MenuItem
            onClick={() => {
              dispatch(featuresActions.useResumeGenerator());
              openCandidateResume({ candidateId });
            }}
          >
            <Shortcuts /> générer cv
            {!hasUsedResumeGenerator && <span className={styles.new}>nouveau</span>}
          </MenuItem>
        )}
        {candidateType !== 'CDI' &&
          (r1Interview?.interviewId === undefined && r2Interview?.interviewId === undefined ? (
            // No existing interviews, we can only create a R1
            <MenuItem
              onClick={() => createR1Mutation.mutate()}
              mutationStatus={createR1Mutation.status}
              keepOpenOnClick
            >
              <Flux /> initier un R1
            </MenuItem>
          ) : (
            <>
              {(isFromInterviewsAgency || isFromInterviewsCGC) && (
                // We can only access the R1 from its unit or from its CGC
                <MenuItem
                  onClick={() =>
                    history.push(
                      getR1CandidateRoute({ candidateId, R1Id: r1Interview?.interviewId }),
                      { origin: history.location.pathname }
                    )
                  }
                >
                  <Flux />
                  {status === CandidateInterviewsSummaryStatus['R1 en cours']
                    ? 'accéder au R1'
                    : 'visualiser le R1'}
                </MenuItem>
              )}
              {(isFromInterviewsAgency || isFromInterviewsCGC) &&
                r1Interview &&
                r2Interview?.interviewId === undefined && (
                  <MenuItem
                    onClick={() => downloadR1InterviewPdf.mutate(r1Interview.interviewId ?? '')}
                    mutationStatus={downloadR1InterviewPdf.status}
                    keepOpenOnClick
                  >
                    <DocumentPdf /> générer le PDF
                  </MenuItem>
                )}
              {[
                CandidateInterviewsSummaryStatus['R2 en cours'],
                CandidateInterviewsSummaryStatus['R2 refusé'],
              ].includes(status) &&
                isFromInterviewsCGC && (
                  <MenuItem onClick={() => showForceValidationModal(true)}>
                    <HandUp /> valider par dérogation
                  </MenuItem>
                )}
              {[
                CandidateInterviewsSummaryStatus['R2 à faire'],
                CandidateInterviewsSummaryStatus['R2 en planification'],
                CandidateInterviewsSummaryStatus['R2 planifié'],
              ].includes(status) &&
                isFromInterviewsCGC &&
                showReassignCGC && (
                  // We can only reassign CGC on an accepted R1 with no R2 from the R1's CGC
                  <MenuItem
                    onClick={() => history.push(`/reassignCgc/${r1Interview?.interviewId}`)}
                  >
                    <Flux /> réattribuer à un CGC
                  </MenuItem>
                )}
              {[
                CandidateInterviewsSummaryStatus['R2 à faire'],
                CandidateInterviewsSummaryStatus['R2 en planification'],
                CandidateInterviewsSummaryStatus['R2 planifié'],
              ].includes(status) &&
                isFromInterviewsCGC && (
                  // We can only create a R2 while on the R1's CGC
                  <MenuItem
                    onClick={() => createR2Mutation.mutate()}
                    mutationStatus={createR2Mutation.status}
                    keepOpenOnClick
                  >
                    <Flux /> initier un R2
                  </MenuItem>
                )}
              {[
                CandidateInterviewsSummaryStatus['R2 à faire'],
                CandidateInterviewsSummaryStatus['R2 en planification'],
                CandidateInterviewsSummaryStatus['R2 planifié'],
              ].includes(status) &&
                isFromInterviewsAgency && (
                  // We can only delete a R1 from its unit and when it has no R2
                  <MenuItem onClick={() => () => setDeleteR1(true)}>
                    <Dustbin /> supprimer le R1
                  </MenuItem>
                )}
              {[
                CandidateInterviewsSummaryStatus['R2 à faire'],
                CandidateInterviewsSummaryStatus['R2 en planification'],
                CandidateInterviewsSummaryStatus['R2 planifié'],
              ].includes(status) && (
                // We can only add actions to R2 planning if it's pending, in planning, or planned
                <MenuItem onClick={() => showR2PlanningModal(true)}>
                  <Calendar /> planifier le R2
                </MenuItem>
              )}
              {(r2Interview?.interviewId !== undefined || r1Interview?.interviewId !== undefined) &&
                [
                  CandidateInterviewsSummaryStatus['R2 à faire'],
                  CandidateInterviewsSummaryStatus['R2 en cours'],
                  CandidateInterviewsSummaryStatus['R2 en planification'],
                  CandidateInterviewsSummaryStatus['R2 planifié'],
                ].includes(status) &&
                isFromInterviewsCGC && (
                  <MenuItem onClick={() => showR1ReopeningModal(true)}>
                    <Undo /> retourner le R1 à l'agence
                  </MenuItem>
                )}
            </>
          ))}
        {r2Interview?.interviewId !== undefined &&
          (isFromInterviewsCGC || isFromInterviewsAgency) && (
            // We can only modify a R2 from its CGC and see it from its R1's unit
            <MenuItem
              onClick={() =>
                history.push(
                  getR2CandidateRoute({
                    candidateId,
                    R2Id: r2Interview?.interviewId,
                  }),
                  { origin: history.location.pathname }
                )
              }
            >
              <Flux />
              {status !== CandidateInterviewsSummaryStatus['R2 en cours']
                ? 'visualiser le R2'
                : 'accéder au R2'}
            </MenuItem>
          )}
        {r2Interview?.interviewId !== undefined &&
          (isFromInterviewsCGC || isFromInterviewsAgency) && (
            // We can only modify a R2 from its CGC and see it from its R1's unit
            <MenuItem
              onClick={() => downloadR2InterviewPdf.mutate(r2Interview.interviewId ?? '')}
              mutationStatus={downloadR2InterviewPdf.status}
              keepOpenOnClick
            >
              <DocumentPdf /> générer le PDF
            </MenuItem>
          )}
        {([
          CandidateInterviewsSummaryStatus['R1 refusé'],
          CandidateInterviewsSummaryStatus['R2 refusé'],
          CandidateInterviewsSummaryStatus['refusé par intérimaire'],
        ].includes(status) ||
          (status === CandidateInterviewsSummaryStatus['R2 terminé'] &&
            r2Interview?.hiringDate &&
            moment(r2Interview.hiringDate).isBefore())) && (
          <MenuItem
            onClick={() =>
              resetInterviewsMutation.mutate({
                r1Id: r1Interview?.interviewId ?? '',
                r2Id: r2Interview?.interviewId,
              })
            }
            mutationStatus={resetInterviewsMutation.status}
            keepOpenOnClick
          >
            <Flux /> initier un nouveau R1
          </MenuItem>
        )}
        {![
          CandidateInterviewsSummaryStatus['R1 refusé'],
          CandidateInterviewsSummaryStatus['refusé par intérimaire'],
          CandidateInterviewsSummaryStatus['R2 refusé'],
          CandidateInterviewsSummaryStatus['validé par dérogation'],
          undefined,
        ].includes(status) &&
          !(
            status === CandidateInterviewsSummaryStatus['R2 terminé'] &&
            moment(r2Interview?.updatedAt).add(1, 'month').isBefore(moment())
          ) && (
            <MenuItem onClick={() => showCloseByTTModal(true)}>
              <Flux /> refus du CDII par le TT
            </MenuItem>
          )}
      </Menu>
      <R1ReopenModal
        isOpen={hasOpenR1ReopeningModal}
        close={() => showR1ReopeningModal(false)}
        status={status ?? CandidateInterviewsSummaryStatus['R1 en cours']}
        interviewId={r2Interview?.interviewId ?? r1Interview?.interviewId ?? ''}
      />
      <InterviewClosedByTTModal
        isOpen={hasOpenClosedByTTModal}
        close={() => showCloseByTTModal(false)}
        status={status ?? CandidateInterviewsSummaryStatus['R1 en cours']}
        interviewId={r2Interview?.interviewId ?? r1Interview?.interviewId ?? ''}
      />
      <ExemptionValidationModal
        isOpen={hasOpenForceValidationModal}
        close={() => showForceValidationModal(false)}
        interviewId={r2Interview?.interviewId ?? r1Interview?.interviewId ?? ''}
        candidateId={candidateId}
      />
      <DeleteR1Modal open={deleteR1} onClose={() => setDeleteR1(false)} r1Interview={r1Interview} />
      <R2PlanningModal
        open={hasOpenR2PlanningModal}
        close={() => showR2PlanningModal(false)}
        candidateId={candidateId}
        agencyId={interviewsAgencyId}
        r1InterviewId={r1Interview?.interviewId}
      />
    </>
  );
};

export default DetailButton;

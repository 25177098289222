import { ArrowLeft } from '@randstad-lean-mobile-factory/react-assets/dist/icons';
import { Button } from '@randstad-lean-mobile-factory/react-components-core';
import { PropsWithChildren } from 'react';
import { useHistory, useLocation } from 'react-router-dom';

import styles from './TopBar.module.scss';
import { TopBarProps } from './TopBar.types';

export const TopBar = ({ children, title, goBackTo }: PropsWithChildren<TopBarProps>) => {
  const history = useHistory();
  const location = useLocation();

  return (
    <div className={styles.topBar}>
      <div className={styles.content}>
        {goBackTo && (
          <div className={styles.goBack}>
            <Button
              rounded
              size="medium"
              onClick={() => {
                if (location.key === 'default') history.push(goBackTo);
                else history.goBack();
              }}
            >
              <ArrowLeft />
            </Button>
          </div>
        )}
        <div className={styles.title}>{title}</div>
      </div>
      <div className={styles.children}>{children}</div>
    </div>
  );
};

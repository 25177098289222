import { CircleCheck } from '@randstad-lean-mobile-factory/react-assets/dist/icons';
import React from 'react';

import { useFetchCandidateWeeklyAvailabilitiesFromURL } from 'src/Hooks/Candidates/useFetchCandidateWeeklyAvailabilitiesFromURL.component';

import styles from './WeeklyAvailabilities.module.scss';
import {
  weeklyAvailabilityDays,
  weeklyAvailabilityEnumToLabelMapping,
} from './WeeklyAvailabilities.utils';

const WeeklyAvailabilities = () => {
  const { data } = useFetchCandidateWeeklyAvailabilitiesFromURL();
  return (
    <div className={styles.gridContainer}>
      <div className={styles.cornerCell}>disponibilité hebdomadaire</div>
      {weeklyAvailabilityDays.map(day => (
        <div key={day} className={styles.headerCell}>
          {day}
        </div>
      ))}

      {data?.weeklyAvailabilities?.weeklyAvailabilityGrids?.map(grid => (
        <React.Fragment key={grid.label}>
          <div className={styles.labelCell}>
            {
              weeklyAvailabilityEnumToLabelMapping[
                grid.label as keyof typeof weeklyAvailabilityEnumToLabelMapping
              ]
            }
          </div>
          {grid?.dayGrids?.map(dg => (
            <div key={dg.day} className={styles.dayCell}>
              {dg.isAvailable ? <CircleCheck variant="fill" color="blue" size="medium" /> : ''}
            </div>
          ))}
        </React.Fragment>
      ))}
    </div>
  );
};

export default WeeklyAvailabilities;

import { SortDown, SortUp } from '@randstad-lean-mobile-factory/react-assets/dist/icons';
import { Menu, MenuItem } from '@randstad-lean-mobile-factory/react-components-core';

import { SORT_OPTIONS, SORT_ORDER } from '../Experience.types';

import styles from './SortOptions.module.scss';
import { Props } from './SortOptions.types';

export const SortOptions = ({
  trigger,
  selectedSortOption,
  setSelectedSortOption,
  selectedSortOrder,
  setSelectedSortOrder,
}: Props) => (
  <Menu
    trigger={trigger}
    anchorOrigin={{ horizontal: 'right', vertical: 'bottom' }}
    transformOrigin={{ horizontal: 'right', vertical: 'top' }}
  >
    <MenuItem
      selected={selectedSortOrder === SORT_ORDER.ASCENDING}
      onClick={() => setSelectedSortOrder(SORT_ORDER.ASCENDING)}
      keepOpenOnClick
    >
      <SortUp size="xsmall" />
      ascendant
    </MenuItem>
    <MenuItem
      selected={selectedSortOrder === SORT_ORDER.DESCENDING}
      onClick={() => setSelectedSortOrder(SORT_ORDER.DESCENDING)}
      keepOpenOnClick
    >
      <SortDown size="xsmall" />
      descendant
    </MenuItem>

    <div className={styles.menuGroup}>trier par</div>

    <MenuItem
      selected={selectedSortOption === SORT_OPTIONS.NAME}
      onClick={() => setSelectedSortOption(SORT_OPTIONS.NAME)}
      keepOpenOnClick
    >
      nom client
    </MenuItem>
    <MenuItem
      selected={selectedSortOption === SORT_OPTIONS.YEAR}
      onClick={() => setSelectedSortOption(SORT_OPTIONS.YEAR)}
      keepOpenOnClick
    >
      ordre chronologique
    </MenuItem>
    <MenuItem
      selected={selectedSortOption === SORT_OPTIONS.HOURS}
      onClick={() => setSelectedSortOption(SORT_OPTIONS.HOURS)}
      keepOpenOnClick
    >
      heures effectuées
    </MenuItem>
    <MenuItem
      selected={selectedSortOption === SORT_OPTIONS.QUALIFICATION}
      onClick={() => setSelectedSortOption(SORT_OPTIONS.QUALIFICATION)}
      keepOpenOnClick
    >
      qualification
    </MenuItem>
  </Menu>
);

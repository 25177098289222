import { createSelector } from '@reduxjs/toolkit';
import moment from 'moment';

import { RootState } from '../RootReducer';

import { FeaturesState } from './Types';

const getFeaturesState = (state: RootState) => state.features;

export const getHasUsedResumeGenerator = createSelector(
  [getFeaturesState],
  (state: FeaturesState) =>
    state.hasUsedResumeGenerator &&
    moment().isBefore({
      year: 2025,
      month: 1,
      day: 28,
    })
);

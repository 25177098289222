import { ComboBox } from '@randstad-lean-mobile-factory/react-form-fields';
import { throttle } from 'lodash-es';
import React, { useCallback, useEffect, useState } from 'react';

import { useFetchLocations } from 'src/Hooks/Locations';
import { MatchingCriteriaLocationWithLabel } from 'src/Services/API';
import { toFetchStatus } from 'src/Services/ReactQuery';

import { Props } from './SelectCity.types';

const SelectCity = ({ control, watch, trigger, name }: Props) => {
  const [keyword, setKeyword] = useState('');
  const mutation = useFetchLocations();
  const watchCity = watch(name);
  useEffect(() => {
    if (watchCity?.label) trigger(name);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [watchCity]);

  // eslint-disable-next-line react-hooks/exhaustive-deps
  const throttledMethod = useCallback(throttle(mutation.mutate, 500), [mutation.mutate]);
  return (
    <ComboBox
      id="city"
      control={control}
      name={name}
      fetchStatus={toFetchStatus(mutation)}
      searchResults={keyword !== '' ? mutation?.data ?? [] : []}
      minLengthToSearch={1}
      keyValueExtractor={(searchResult: MatchingCriteriaLocationWithLabel) => ({
        key: searchResult.zipCode,
        value:
          searchResult?.label && searchResult?.zipCode
            ? `${searchResult.label} - ${searchResult.zipCode}`
            : '',
      })}
      onSearch={(word: string) => {
        setKeyword(word);
        word !== '' && throttledMethod(word);
      }}
      disableClearable
    />
  );
};

export default SelectCity;

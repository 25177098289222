import { useAuth0 } from '@auth0/auth0-react';

interface AuthenticatedUser {
  givenName?: string;
  familyName?: string;
  email?: string;
}

export const useAuthenticatedUser = () => {
  const { user } = useAuth0();
  return {
    givenName: user?.given_name,
    familyName: user?.family_name,
    email: user?.email,
  } as AuthenticatedUser;
};

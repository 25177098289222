import { Route, Switch, useLocation, useRouteMatch } from 'react-router-dom';

import { useWatchForEdpURLParam } from 'src/Hooks/EDP/useWatchForEdpURLParam';
import { LocationState } from 'src/Hooks/Modals';
import { useWatchMissionId } from 'src/Hooks/Routing/useWatchMissionId';

import AddNewTalent from '../AddNewTalent/AddNewTalent.component';
import CandidateFile from '../CandidateFile';
import CandidateRecruitingProcess from '../CandidateRecruitingProcess';
import { CandidateResults } from '../CandidateResults';
import Home from '../Home';
import InterviewDashboard from '../InterviewDashboard';

import styles from './AppRoot.module.scss';
import TopBar from './TopBar';

export const AppRoot = () => {
  const match = useRouteMatch();
  const location = useLocation<LocationState>();
  const locationState = location.state;
  const background = locationState?.background;

  useWatchMissionId();
  useWatchForEdpURLParam();

  return (
    <div className={styles.container}>
      <TopBar />
      <Switch location={background || location}>
        <Route exact path={match.path} component={Home} />
        <Route exact path="/results" component={CandidateResults} />
        <Route exact path="/interviews/:dashboardMenu" component={InterviewDashboard} />
        <Route exact path="/new-talent" component={AddNewTalent} />
        <Route
          exact
          path="/candidate/:candidateId?/file/:firstSection?/:secondSection?"
          component={CandidateFile}
        />
        <Route
          exact
          path="/candidate/:candidateId?/recruit/:firstSection?/:id?/:secondSection?"
          component={CandidateRecruitingProcess}
        />
      </Switch>
    </div>
  );
};

import { SegmentedMenu } from '@randstad-lean-mobile-factory/react-components-core';
import React, { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';

import ExportModal from 'src/Containers/Modals/ExportModal';
import { useFetchConsultantsEstablishments } from 'src/Hooks/Consultants/useFetchConsultantsEstablishments';
import { useFetchInterviewsStatisticsPerAgency } from 'src/Hooks/Interviews/useFetchInterviewsStatisticsPerAgency';
import { getStatsSelectedAgencies } from 'src/Redux/InterviewsStats/Selector';
import { FETCH_STATUS } from 'src/Redux/Types';
import { toFetchStatus } from 'src/Services/ReactQuery';

import styles from './InterviewsStatsPerAgency.module.scss';
import InterviewStatsTable from './InterviewStatsTable';

const InterviewsStatsPerAgency = () => {
  const consultantEtablishmentsQuery = useFetchConsultantsEstablishments();
  const consultantEtablishmentsFetchStatus = toFetchStatus(consultantEtablishmentsQuery);
  const [selectedRegionTab, setSelectedRegionTab] = useState('');
  const agencyIds = useSelector(getStatsSelectedAgencies);
  const statsPerAgencyQuery = useFetchInterviewsStatisticsPerAgency();
  const selectedRegions = consultantEtablishmentsQuery.data?.regions
    ?.map(region => {
      return {
        ...region,
        zones: region.zones
          ?.map(zone => {
            return {
              ...zone,
              agencies: zone.agencies?.filter(agency => agencyIds.includes(agency.agencyId ?? '')),
            };
          })
          .filter(zone => zone.agencies !== undefined && zone.agencies?.length > 0),
      };
    })
    .filter(region => region.zones !== undefined && region.zones.length > 0);

  const agenciesWithStats = selectedRegions?.map(region => {
    return {
      ...region,
      zones: region.zones?.map(zone => {
        return {
          ...zone,
          agencies: zone.agencies?.map(agency => {
            const agencyStats = statsPerAgencyQuery.data?.find(
              item => item.agencyId === agency.agencyId
            );
            return {
              ...agency,
              closedR1WithinMonth: agencyStats?.closedR1WithinMonth,
              closedR2WithinMonth: agencyStats?.closedR2WithinMonth,
              closedR2: agencyStats?.closedR2,
            };
          }),
        };
      }),
    };
  });
  const regionTab = agenciesWithStats?.find(
    region => region.regionTitle?.replace('Direction ', '') === selectedRegionTab
  );
  useEffect(() => {
    if (
      selectedRegions !== undefined &&
      selectedRegions.length > 0 &&
      consultantEtablishmentsFetchStatus === FETCH_STATUS.FULFILLED
    ) {
      setSelectedRegionTab(selectedRegions[0].regionTitle?.replace('Direction ', '') ?? '');
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [consultantEtablishmentsFetchStatus]);

  return (
    <div className={styles.container}>
      {consultantEtablishmentsFetchStatus === FETCH_STATUS.FULFILLED &&
        selectedRegions !== undefined &&
        selectedRegions.length > 0 && (
          <>
            <ExportModal />
            <div className={styles.menuContainer}>
              <SegmentedMenu
                controls={
                  selectedRegions?.map(
                    region => region.regionTitle?.replace('Direction ', '') ?? ''
                  ) ?? []
                }
                selected={selectedRegionTab}
                keyValueExtractor={region => ({ key: region, value: region })}
                bottomBarClassName={styles.tabBar}
                onSelectionChange={region => {
                  setSelectedRegionTab(region);
                }}
              />
            </div>
            <InterviewStatsTable region={regionTab} />
          </>
        )}
    </div>
  );
};

export default InterviewsStatsPerAgency;

import { EnumCandidateWeeklyAvailabilityGridsDtoLabel } from 'src/Services/API';

export const weeklyAvailabilityDays = ['lun', 'mar', 'mer', 'jeu', 'ven', 'sam', 'dim'];

export const weeklyAvailabilityEnumToLabelMapping = {
  [EnumCandidateWeeklyAvailabilityGridsDtoLabel.DaySchedule]: 'heures journées',
  [EnumCandidateWeeklyAvailabilityGridsDtoLabel.MorningTeam]: 'matinée',
  [EnumCandidateWeeklyAvailabilityGridsDtoLabel.NoonTeam]: 'après-midi',
  [EnumCandidateWeeklyAvailabilityGridsDtoLabel.NightTeam]: 'nuit',
};

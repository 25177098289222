import { WithLightTitle } from '@randstad-lean-mobile-factory/react-components-core';
import { Flux } from '@randstad-lean-mobile-factory/react-components-ui-shared';
import { ComboBox } from '@randstad-lean-mobile-factory/react-form-fields';
import { throttle } from 'lodash-es';
import React, { useCallback, useState } from 'react';
import { useController } from 'react-hook-form';

import { useFetchSkills } from 'src/Hooks/Skills';
import { toFetchStatus } from 'src/Services/ReactQuery';

import RemoveItem from '../../Components/RemoveItem/RemoveItem.component';

import styles from './SelectHardSkillComboBox.module.scss';
import { Props } from './SelectHardSkillComboBox.types';

const SelectHardSkillComboBox = ({ control, index }: Props) => {
  const hardSkillsController = useController({
    control,
    name: 'hardSkills',
  });
  const skill = hardSkillsController.field.value[index];
  const mutation = useFetchSkills();
  const mutationData = mutation?.data?.map(item => {
    return { id: item.identifier, label: item.label };
  });

  // eslint-disable-next-line react-hooks/exhaustive-deps
  const throttledMethod = useCallback(throttle(mutation.mutate, 500), [mutation.mutate]);
  const [keyword, setKeyword] = useState('');
  return (
    skill && (
      <div className={styles.container}>
        <WithLightTitle
          title={'compétence technique détectée'}
          className={styles.parsedDocumentLabelContainer}
        >
          <p>{skill.parsedDocumentLabel}</p>
        </WithLightTitle>
        <Flux className={styles.flux} />
        <div className={styles.hardSkillContainer}>
          <WithLightTitle
            title={`compétence technique #${index + 1}`}
            className={styles.hardSkillTitle}
          >
            <div className={styles.comboBoxContainer}>
              <ComboBox
                id="hardSkills"
                control={control}
                name={`hardSkills.${index}.selectedItem`}
                keyValueExtractor={searchResult => ({
                  key: searchResult.id,
                  value: searchResult.label ?? '',
                })}
                placeholder="sélectionner"
                onSearch={word => {
                  setKeyword(word);
                  word !== '' && throttledMethod(word);
                }}
                searchResults={
                  keyword !== ''
                    ? mutationData?.filter(
                        hardSkill =>
                          !hardSkillsController?.field?.value?.some(
                            candidateHardSkill =>
                              candidateHardSkill.selectedItem.id === hardSkill.id
                          )
                      ) ?? []
                    : []
                }
                disableClearable
                minLengthToSearch={1}
                className={styles.comboBox}
                fetchStatus={toFetchStatus(mutation)}
                isOptionEqualToValue={(option, value) => option.id === value.id}
              />
              <RemoveItem
                onClick={() => {
                  hardSkillsController.field.onChange(
                    hardSkillsController.field.value.filter((_, idx) => idx !== index)
                  );
                }}
                className={styles.removeItem}
              />
            </div>
          </WithLightTitle>
        </div>
        <div className={styles.separator} />
      </div>
    )
  );
};
export default SelectHardSkillComboBox;

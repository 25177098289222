import React from 'react';

import { useFetchCandidateDetailsFromURL } from 'src/Hooks/Candidates/useFetchCandidateDetailsFromURL';

import AvailabilityAndContractType from './AvailabilityAndContractType';
import BirthInformations from './BirthInformations';
import ContactModes from './ContactModes';
import styles from './Identity.module.scss';
import Qualifications from './Qualifications';
import Visualization from './Visualization';
import WeeklyAvailabilities from './WeeklyAvailabilities';

const Identity = () => {
  const candidate = useFetchCandidateDetailsFromURL().data;
  return (
    <div>
      <div className={styles.title}>informations personnelles</div>
      <Visualization candidate={candidate} />
      <BirthInformations />
      <div className={styles.separator} />
      <div className={styles.additionalInformationTitle}>informations complémentaires</div>
      <Qualifications mainQualificationId={candidate?.qualificationId ?? ''} />
      <AvailabilityAndContractType candidate={candidate} />
      <WeeklyAvailabilities />
      <div className={styles.separator}></div>
      <ContactModes candidate={candidate} />
    </div>
  );
};

export default Identity;

import { Bullet, OtherHorizontal } from '@randstad-lean-mobile-factory/react-assets/dist/icons';
import { ServerErrorYellow } from '@randstad-lean-mobile-factory/react-assets/dist/illustrations';
import { Button, Loader, Tab, Tabs } from '@randstad-lean-mobile-factory/react-components-core';
import { useSelector } from 'react-redux';
import { useHistory, useParams } from 'react-router-dom';

import DetailButton from 'src/Components/DetailButton';
import { TopBar } from 'src/Components/TopBar';
import { useFetchCandidateDetailsFromURL } from 'src/Hooks/Candidates/useFetchCandidateDetailsFromURL';
import { useFetchCandidateInterviewsIdAndStatus } from 'src/Hooks/Candidates/useFetchCandidateInterviewsIdAndStatus';
import { getHasUsedResumeGenerator } from 'src/Redux/Features/Selector';
import { FETCH_STATUS } from 'src/Redux/Types';
import { mergeSeveralFetchStatus, toFetchStatus } from 'src/Services/ReactQuery';
import { getEditionRoute } from 'src/Services/Routing';
import { pluralFormat } from 'src/Utils/pluralFormat';

import styles from './CandidateFile.module.scss';
import { navigation } from './CandidateFile.types';
import CandidateFileForm from './CandidateFileForm';
import Sidebar from './Sidebar';

const CandidateFile = () => {
  const history = useHistory();
  const params = useParams<{
    firstSection: string;
    candidateId: string;
  }>();
  const candidateDetailsQuery = useFetchCandidateDetailsFromURL();
  const candidateInterviewsIdAndStatusQuery = useFetchCandidateInterviewsIdAndStatus(
    params.candidateId
  );
  const fetchStatus = mergeSeveralFetchStatus([
    toFetchStatus(candidateDetailsQuery),
    toFetchStatus(candidateInterviewsIdAndStatusQuery),
  ]);

  const hasUsedResumeGenerator = useSelector(getHasUsedResumeGenerator);

  return (
    <div className={styles.container}>
      <TopBar goBackTo="/result" title="détails talent">
        <DetailButton
          candidateId={params.candidateId}
          candidateType={candidateDetailsQuery.data?.statusCode}
          candidateInterviewsIdAndStatus={candidateInterviewsIdAndStatusQuery.data}
          showResumeGenerator
          trigger={
            <Button size="large" rounded>
              <OtherHorizontal />
              {!hasUsedResumeGenerator && (
                <Bullet color="warm-red" variant="fill" size="xsmall" className={styles.bullet} />
              )}
            </Button>
          }
          anchorOrigin={{ horizontal: 'right', vertical: 'bottom' }}
          transformOrigin={{ horizontal: 'right', vertical: 'top' }}
        />
      </TopBar>
      {fetchStatus === FETCH_STATUS.PENDING ? (
        <div className={styles.illustration}>
          <Loader size="large" />
          chargement des détails talent en cours
        </div>
      ) : fetchStatus === FETCH_STATUS.REJECTED ? (
        <div className={styles.illustration}>
          <ServerErrorYellow />
          désolé, une erreur s'est produite lors du chargement
        </div>
      ) : (
        <>
          <div className={styles.tabsContainer}>
            <Tabs
              color="navy"
              size="medium"
              hideSeparator
              value={params.firstSection}
              onChange={section => {
                history.replace(
                  getEditionRoute({
                    candidateId: params.candidateId,
                    section: section,
                  })
                );
              }}
            >
              {Object.entries(navigation).map(([key, section]) => (
                <Tab key={key} value={key}>
                  {section.name}
                </Tab>
              ))}
            </Tabs>
          </div>
          <div className={styles.tabContainer}>
            <div className={styles.sideBarContainer}>
              <div className={styles.candidateName}>
                <p>vous êtes sur le profil de :</p>
                {candidateDetailsQuery.data?.firstName} {candidateDetailsQuery.data?.name}
              </div>
              <div className={styles.candidateContract}>
                {candidateDetailsQuery.data?.statusCode ?? 'type de contrat non renseigné'} |{' '}
                {pluralFormat(candidateDetailsQuery.data?.nbHoursWorked ?? 0, 'heure')}
              </div>
              <Sidebar />
            </div>
            <CandidateFileForm />
          </div>
        </>
      )}
    </div>
  );
};

export default CandidateFile;

import { useAuth0 } from '@auth0/auth0-react';
import { Settings } from '@randstad-lean-mobile-factory/react-assets/dist/icons';
import {
  Button,
  ParametersPopup as ParametersPopupPrimitive,
} from '@randstad-lean-mobile-factory/react-components-core';

import styles from './ParametersPopup.module.scss';

export const ParametersPopup = () => {
  const { user, logout } = useAuth0();

  return (
    <ParametersPopupPrimitive
      trigger={
        <div>
          <Button.Tertiary className={styles.gearButton}>
            <Settings variant="fill" />
          </Button.Tertiary>
        </div>
      }
      anchorOrigin={{ horizontal: 'right', vertical: 'bottom' }}
      transformOrigin={{ horizontal: 'right', vertical: 'top' }}
      version={process.env.REACT_APP_VERSION}
      user={user}
      onLogout={() =>
        logout({ logoutParams: { returnTo: process.env.REACT_APP_AUTH0_REDIRECT_URI } })
      }
    />
  );
};

import { AutoSuggest, WithLightTitle } from '@randstad-lean-mobile-factory/react-components-core';
import { throttle } from 'lodash-es';
import React, { useCallback, useState } from 'react';

import { useFetchDiplomas } from 'src/Hooks/Diplomas/useFetchDiplomas';
import { MatchingCriteriaItemWithLabel } from 'src/Services/API';
import { toFetchStatus } from 'src/Services/ReactQuery';

import { Props } from './SelectDiplomas.types';

const SelectDiplomas = ({ selectedDiplomas, setDiplomas }: Props) => {
  const [keyword, setKeyword] = useState('');
  const mutation = useFetchDiplomas();

  // eslint-disable-next-line react-hooks/exhaustive-deps
  const throttledMethod = useCallback(throttle(mutation.mutate, 500), [mutation.mutate]);
  return (
    <WithLightTitle
      title={`${keyword !== '' && mutation.data ? mutation.data.length : '-'} diplômes disponibles`}
    >
      <AutoSuggest
        id="diplômes"
        value={selectedDiplomas ?? []}
        keyValueExtractor={(searchResult: MatchingCriteriaItemWithLabel) => ({
          key: searchResult.identifier,
          value: searchResult.label ?? '',
        })}
        onSearch={(word: string) => {
          setKeyword(word);
          word !== '' && throttledMethod(word);
        }}
        onChange={diplomas => {
          setDiplomas([...diplomas]);
          setKeyword('');
        }}
        searchResults={keyword !== '' ? mutation.data ?? [] : []}
        placeholder="1 caractère minimum"
        fetchStatus={toFetchStatus(mutation)}
      />
    </WithLightTitle>
  );
};

export default SelectDiplomas;

import { Candidate } from 'src/Services/API';

export const getContactModes = (candidate: Candidate | undefined): string[] => {
  const contactModes: string[] = [];

  if (candidate === undefined) {
    return contactModes;
  }

  if (candidate.flagAcceptSMS) contactModes.push('via sms');
  if (candidate.smartphoneApplication) contactModes.push('via app smartphone');
  if (candidate.facebookApplication) contactModes.push('via app facebook');
  if (candidate.flagAcceptEmailMission) contactModes.push('via emails');
  if (candidate.flagAcceptNotifMission) contactModes.push('via notifications');
  if (candidate.flagAcceptSMSMission) contactModes.push('via sms de missions');

  return contactModes;
};

import { WithLightTitle } from '@randstad-lean-mobile-factory/react-components-core';
import moment from 'moment';

import { toDate } from 'src/Utils/moment';

import styles from './AvailabilityAndContractType.module.scss';
import { AvailabilityAndContractTypeProps } from './AvailabilityAndContractType.types';

const AvailabilityAndContractType = ({ candidate }: AvailabilityAndContractTypeProps) => {
  return (
    <div className={styles.container}>
      <WithLightTitle title="date de disponibilité">
        {moment(toDate(candidate?.availabilityForConsultantDate)).format('L')}
      </WithLightTitle>
      <WithLightTitle title="type de contrat">{`${candidate?.contractAccepted
        .join(', ')
        .toLowerCase()}`}</WithLightTitle>
    </div>
  );
};

export default AvailabilityAndContractType;

import { groupBy, orderBy } from 'lodash-es';
import moment from 'moment';

import { CandidateExperience, CandidateExperienceWithCompany } from 'src/Services/API';
import { pluralFormat } from 'src/Utils/pluralFormat';

import { SORT_OPTIONS, SORT_ORDER } from './Experience.types';

export const getUniqueExperienceQualifications = (experiences: CandidateExperience[]): string[] => {
  return Array.from(
    new Set(experiences.map(experience => experience.qualification?.label).filter(Boolean))
  );
};

export const getExperienceDuration = (experiences: CandidateExperience[]) => {
  const totalDays = totalMissionDays(experiences);
  const totalWeeks = Math.floor(totalDays / 5);
  const totalMonths = Math.floor(totalDays / 22);
  return totalMonths > 0
    ? `${totalMonths} mois`
    : totalWeeks > 0
    ? pluralFormat(totalWeeks, 'semaine')
    : totalDays > 0
    ? pluralFormat(totalDays, 'jour')
    : 'durée inconnue';
};

export const getExperienceSupportingLines = (experiences: CandidateExperience[]): string[] => {
  experiences.sort((a, b) => {
    const dateA = new Date(a?.startDate ?? '');
    const dateB = new Date(b?.startDate ?? '');
    return (dateA.getTime() ?? 0) - (dateB.getTime() ?? 0);
  });
  const firstDate = experiences.length > 0 ? moment(experiences[0].startDate).format('L') : '';
  const lastDate =
    experiences.length > 0 ? moment(experiences[experiences.length - 1].endDate).format('L') : '';

  const qualifications = getUniqueExperienceQualifications(experiences);
  const qualificationLimit = 3;

  return [
    qualifications.length <= qualificationLimit
      ? qualifications.join(', ')
      : qualifications.slice(0, qualificationLimit).join(', ') +
        ' et ' +
        pluralFormat(qualifications.length - qualificationLimit, 'autre qualification'),
    [
      `du ${firstDate} au ${lastDate}`,
      pluralFormat(experiences.length, 'contrat'),
      getExperienceDuration(experiences),
    ]
      .filter(Boolean)
      .join(' | '),
  ];
};

const totalMissionDays = (experiences: CandidateExperience[]): number => {
  const totalDuration = experiences.reduce(
    (cum: number, cur: CandidateExperience) =>
      cum +
      moment(cur.endDate).add(1, 'day').diff(moment(cur.startDate), 'day') -
      moment(cur.endDate).add(1, 'day').diff(moment(cur.startDate), 'weeks') * 2,
    0
  );
  return totalDuration;
};

export const groupExperiences = (
  experiences: CandidateExperienceWithCompany[],
  internal: boolean,
  sortOption: SORT_OPTIONS,
  sortOrder: SORT_ORDER
) => {
  let experiencesWithCompany = experiences.map(experienceWithCompany => ({
    companyId: experienceWithCompany.companyId,
    companyName: experienceWithCompany.companyName,
    experiences: internal
      ? experienceWithCompany.experiencesByCompany.internalExperiences
      : experienceWithCompany.experiencesByCompany.externalExperiences,
  }));
  if (sortOption === SORT_OPTIONS.QUALIFICATION) {
    experiencesWithCompany = experiencesWithCompany.flatMap(experienceWithCompany =>
      Object.values(
        groupBy(experienceWithCompany.experiences, experience => experience.qualification?.id)
      ).map(experiences => ({
        ...experienceWithCompany,
        experiences,
      }))
    );
  }

  const orderedExperience = orderBy(
    experiencesWithCompany.filter(
      experienceWithCompany => experienceWithCompany.experiences.length
    ),
    experienceWithCompany => {
      switch (sortOption) {
        case SORT_OPTIONS.HOURS:
          return totalMissionDays(experienceWithCompany.experiences);
        case SORT_OPTIONS.NAME:
          return experienceWithCompany.companyName;
        default:
          return new Date(experienceWithCompany.experiences[0].startDate ?? '');
      }
    },
    sortOrder === SORT_ORDER.ASCENDING ? 'asc' : 'desc'
  );

  const groupedExperience = groupBy(orderedExperience, experienceWithCompany => {
    switch (sortOption) {
      case SORT_OPTIONS.HOURS:
        return totalMissionDays(experienceWithCompany.experiences);
      case SORT_OPTIONS.NAME:
        return experienceWithCompany.companyName?.slice(0, 1).toUpperCase();
      case SORT_OPTIONS.YEAR:
        return new Date(experienceWithCompany.experiences[0].startDate ?? '')?.getFullYear();
      case SORT_OPTIONS.QUALIFICATION:
        return experienceWithCompany.experiences[0].qualification?.label;
    }
  });

  return orderBy(
    Object.entries(groupedExperience),
    group => group[0],
    sortOrder === SORT_ORDER.ASCENDING ? 'asc' : 'desc'
  );
};

import SkillsByJob from 'src/Containers/CandidateFile/Qualifications/SkillsByJob';
import Tests from 'src/Containers/CandidateFile/Qualifications/Tests';

import Experience from './Experience';
import Diplomas from './Qualifications/Habilitations';
import Agencies from './Relationship/Agencies';
import Documents from './Synthese/Documents';
import Identity from './Synthese/Identity';

interface CandidateFileNavigationNode {
  name: string;
  default: string;
  path: string;
  routes: { name: string; linkTo: string; component?: () => JSX.Element; path: string }[];
}

type CandidateFileNav = { [key: string]: CandidateFileNavigationNode };

const baseRoute = '/candidate/:candidateId/file';

export const navigation: CandidateFileNav = {
  synthese: {
    name: 'synthèse',
    default: './synthese/identity',
    path: `${baseRoute}/synthese`,
    routes: [
      {
        name: 'identité',
        linkTo: './identity',
        path: `${baseRoute}/synthese/identity`,
        component: Identity,
      },
      {
        name: 'documents',
        linkTo: './documents',
        path: `${baseRoute}/synthese/documents`,
        component: Documents,
      },
    ],
  },
  experiences: {
    name: 'expériences',
    default: './experiences/internal',
    path: `${baseRoute}/experiences`,
    routes: [
      {
        name: 'expériences randstad',
        linkTo: './internal',
        path: `${baseRoute}/experiences/internal`,
        component: () => <Experience internal={true} />,
      },
      {
        name: 'expériences externes',
        linkTo: './external',
        path: `${baseRoute}/experiences/external`,
        component: () => <Experience internal={false} />,
      },
    ],
  },
  qualifications: {
    name: 'qualifications',
    default: './qualifications/skills',
    path: `${baseRoute}/qualifications`,
    routes: [
      {
        name: 'métiers et compétences',
        linkTo: './skills',
        path: `${baseRoute}/qualifications/skills`,
        component: SkillsByJob,
      },
      {
        name: 'diplômes et habilitations',
        linkTo: './diplomas',
        path: `${baseRoute}/qualifications/diplomas`,
        component: Diplomas,
      },
      {
        name: 'tests',
        linkTo: './tests',
        path: `${baseRoute}/qualifications/tests`,
        component: Tests,
      },
    ],
  },
  relationship: {
    name: 'relations',
    default: './relationship/agencies',
    path: `${baseRoute}/relationship`,
    routes: [
      // {
      //   name: 'vie intérimaire',
      //   linkTo: './timeline',
      //   path: `${baseRoute}/relationship/timeline`,
      // },
      {
        name: "agence(s) d'inscription",
        linkTo: './agencies',
        path: `${baseRoute}/relationship/agencies`,
        component: Agencies,
      },
    ],
  },
};

import {
  FetchButton,
  HorizontalCardWithTitle,
  toast,
  WithLightTitle,
} from '@randstad-lean-mobile-factory/react-components-core';
import { useFormWithZodResolver } from '@randstad-lean-mobile-factory/react-form-fields';
import moment from 'moment';
import { useContext, useEffect, useMemo } from 'react';
import ContentLoader from 'react-content-loader';
import { useController, useFormState } from 'react-hook-form';
import { useSelector } from 'react-redux';
import { useParams } from 'react-router';

import DatePickerInputWithDisplayMode from 'src/Components/WithDisplayMode/DatePickerInputWithDisplayMode';
import TextAreaWithDisplayMode from 'src/Components/WithDisplayMode/TextAreaWithDisplayMode';
import ToggleSwitchWithDisplayMode from 'src/Components/WithDisplayMode/ToggleSwitchWithDisplayMode';
import { useFetchCandidateETests } from 'src/Hooks/CandidateR2/useFetchCandidateETests';
import { useFetchCandidateR2FromURL } from 'src/Hooks/CandidateR2/useFetchCandidateR2FromURL';
import { useUpdateCandidateR2 } from 'src/Hooks/CandidateR2/useUpdateCandidateR2';
import { useFetchCandidateDetailsFromURL } from 'src/Hooks/Candidates/useFetchCandidateDetailsFromURL';
import { useFetchConsultantInfo } from 'src/Hooks/Consultants/useFetchConsultantInfo';
import { FormStatusContext } from 'src/Hooks/Navigation/useFormNavigationContextElements';
import { getPerimeterUnits } from 'src/Redux/Perimeter/Selectors';
import { FETCH_STATUS } from 'src/Redux/Types';
import { CandidateETests } from 'src/Services/API';
import { mergeSeveralFetchStatus, toFetchStatus } from 'src/Services/ReactQuery';
import { pluralFormat } from 'src/Utils/pluralFormat';

import styles from './ContextInformations.module.scss';
import { contextInformationSchema } from './ContextInformations.types';

const ContextInformations = () => {
  const units = useSelector(getPerimeterUnits);
  const params = useParams<{
    R2Id: string;
  }>();
  const candidateR2Update = useUpdateCandidateR2(params.R2Id ?? '');
  const r2UpdateFetchStatus = toFetchStatus(candidateR2Update);

  const candidateR2 = useFetchCandidateR2FromURL();
  const candidateR2FetchStatus = toFetchStatus(candidateR2);
  const candidateDetails = useFetchCandidateDetailsFromURL();
  const candidateDetailsFetchStatus = toFetchStatus(candidateDetails);

  const eTests = useFetchCandidateETests();

  const consultantInfo = useFetchConsultantInfo(candidateR2.data?.consultantId ?? '');
  const consultantInfoFetchStatus = toFetchStatus(consultantInfo);

  const fetchStatus = mergeSeveralFetchStatus([
    candidateR2FetchStatus,
    candidateDetailsFetchStatus,
    consultantInfoFetchStatus,
  ]);

  const { control, handleSubmit, setValue, reset, getValues } = useFormWithZodResolver({
    schema: contextInformationSchema,
    defaultValues: {
      interviewDate: candidateR2.data?.interviewDate,
      hiringDate: candidateR2.data?.hiringDate,
      hasTests: candidateR2.data?.tests?.hasTests ?? false,
      isEligibleToFreeJobs: candidateR2.data?.isEligibleToFreeJobs ?? false,
      testsComment: candidateR2.data?.tests?.comment ?? '',
    },
  });

  const interviewDateController = useController({ name: 'interviewDate', control });
  const r2HiringDateController = useController({ name: 'hiringDate', control });
  const isEligibleToFreeJobs = useController({ name: 'isEligibleToFreeJobs', control });
  const hasTestsController = useController({ name: 'hasTests', control });
  const testsCommentController = useController({ name: 'testsComment', control });

  const canModifyR2 = useMemo(
    () => candidateR2.data && units.includes(candidateR2.data.cgcId) && !candidateR2.data.isClosed,
    [candidateR2.data, units]
  );

  const contextHandleSubmit = () =>
    handleSubmit(values => {
      candidateR2.data &&
        canModifyR2 &&
        candidateR2Update.mutate({
          ...candidateR2.data,
          interviewDate: values.interviewDate,
          hiringDate: values.hiringDate,
          isEligibleToFreeJobs: values.isEligibleToFreeJobs,
          tests: {
            hasTests: values.hasTests,
            comment: values.testsComment,
          },
        });
      reset(values);
    });

  useEffect(() => {
    if (candidateR2.data?.interviewDate)
      setValue('interviewDate', new Date(candidateR2.data.interviewDate));
    if (candidateR2.data?.hiringDate) setValue('hiringDate', new Date(candidateR2.data.hiringDate));
    if (candidateR2.data?.tests?.hasTests) setValue('hasTests', candidateR2.data.tests.hasTests);
    if (candidateR2.data?.tests?.comment) setValue('testsComment', candidateR2.data.tests.comment);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [candidateR2FetchStatus, candidateR2.isSuccess]);

  const { isDirty, isSubmitting, isSubmitted, isSubmitSuccessful } = useFormState({
    control: control,
  });
  const formStatusContext = useContext(FormStatusContext);
  useEffect(() => {
    formStatusContext.setHasFormBeenTouched(isDirty);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isDirty]);

  useEffect(() => {
    formStatusContext.updateValidationStatus({
      handleSubmit: contextHandleSubmit,
      fetchStatus: r2UpdateFetchStatus,
      formStateElements: { isSubmitSuccessful, isSubmitted, isSubmitting },
    });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isSubmitted, isSubmitting, isSubmitSuccessful, r2UpdateFetchStatus]);

  return (
    <>
      <div className={styles.title}>fiche candidat</div>
      {fetchStatus === FETCH_STATUS.PENDING &&
        new Array(6).fill(null).map((_, index) => (
          <ContentLoader key={`loader-${index}`} height="5rem" width="100%" uniqueKey="r2context">
            <rect x="2%" y="10" rx="4" ry="4" width="80%" height="50" />
          </ContentLoader>
        ))}
      {fetchStatus === FETCH_STATUS.FULFILLED && (
        <>
          <div className={styles.candidateCard}>
            <div className={styles.rowContainer}>
              <WithLightTitle title="nom candidat" className={styles.subtitle}>
                <div className={styles.name}>{candidateDetails.data?.name}</div>
              </WithLightTitle>
              <WithLightTitle title="prénom candidat" className={styles.subtitle}>
                <div>{candidateDetails.data?.firstName}</div>
              </WithLightTitle>
            </div>
            <WithLightTitle title="lieu de résidence" className={styles.subtitle}>
              <div>{`${candidateDetails.data?.zipCode} ${candidateDetails.data?.city}`}</div>
            </WithLightTitle>
            <WithLightTitle title="agence interlocuteur" className={styles.subtitle}>
              <div>{`${candidateDetails.data?.mainAgency?.name}`}</div>
            </WithLightTitle>
            <div className={styles.rowContainer}>
              <WithLightTitle title="date entretien R1" className={styles.subtitle}>
                <div className={styles.r1Date}>
                  {moment(candidateR2.data?.r1Data.interviewDate).format('DD/MM/YYYY')}
                </div>
              </WithLightTitle>
              <div className={styles.r2Date}>
                <WithLightTitle title="date entretien R2" className={styles.subtitle}>
                  <DatePickerInputWithDisplayMode
                    date={interviewDateController.field.value}
                    onSelectDate={interviewDateController.field.onChange}
                    isDisplayMode={candidateR2.data?.isClosed}
                  />
                </WithLightTitle>
              </div>
              <WithLightTitle title="date prévisionnelle embauche CDI" className={styles.subtitle}>
                <DatePickerInputWithDisplayMode
                  date={r2HiringDateController.field.value}
                  onSelectDate={r2HiringDateController.field.onChange}
                  isDisplayMode={candidateR2.data?.isClosed}
                />
              </WithLightTitle>
            </div>
            {r2HiringDateController.field.value &&
              interviewDateController.field.value &&
              moment(interviewDateController.field.value).isAfter(
                moment(r2HiringDateController.field.value)
              ) && (
                <div className={styles.warning}>
                  Vous avez saisi une date d'embauche antérieure à la date de l'entretien
                </div>
              )}
            <ToggleSwitchWithDisplayMode
              onCheckStatusChange={isEligibleToFreeJobs.field.onChange}
              checked={isEligibleToFreeJobs.field.value}
              isDisplayMode={!canModifyR2}
              toggleLabel="poste éligible à emplois francs"
            />
            <WithLightTitle title="interlocuteur CGC" className={styles.subtitle}>
              <div>{`${consultantInfo.data?.name} ${consultantInfo.data?.firstName}`}</div>
            </WithLightTitle>
          </div>
          <div className={styles.separator} />
          <div className={styles.title}>tests</div>
          <div className={styles.candidateCard}>
            <ToggleSwitchWithDisplayMode
              onCheckStatusChange={hasTestsController.field.onChange}
              checked={hasTestsController.field.value}
              isDisplayMode={!canModifyR2}
              toggleLabel="le candidat a passé des tests spécifiques client ?"
            />
            <WithLightTitle
              title="quelle est la nature de ces tests spécifiques ?"
              className={styles.subtitle}
              rightTitleComponent={
                <div className={styles.textLength}>
                  {pluralFormat(testsCommentController.field.value?.length ?? 0, 'caractère')}
                </div>
              }
            >
              <TextAreaWithDisplayMode
                title="tests"
                className={styles.tests}
                isDisplayMode={!canModifyR2}
                placeholder="décrivez la nature de ces tests"
                value={testsCommentController.field.value}
                onChange={testsCommentController.field.onChange}
              />
            </WithLightTitle>

            {eTests.data &&
              eTests.data.map((test: CandidateETests) => (
                <HorizontalCardWithTitle
                  key={test.id}
                  title={test.label ?? ''}
                  subtitles={[
                    test?.testType ?? '',
                    `passé le ${test.completedOn?.day}/${test.completedOn?.month}/${test.completedOn?.year} | agence ${test.agencyId} | note ${test.completionRate}`,
                  ]}
                />
              ))}
          </div>
        </>
      )}
      {canModifyR2 && (
        <FetchButton
          title="valider informations contexte"
          errorTitle="réessayer"
          fetchStatus={isSubmitting ? FETCH_STATUS.FULFILLED : r2UpdateFetchStatus}
          className={styles.button}
          onClick={contextHandleSubmit()}
          onSuccess={() => {
            toast.success('Votre R2 a été modifié');
            reset(getValues());
          }}
          onError={() => toast.error("Erreur lors de l'enregistrement de votre R2")}
        />
      )}
    </>
  );
};

export default ContextInformations;

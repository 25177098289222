import { createSlice } from '@reduxjs/toolkit';

import { FeaturesState } from './Types';

const initialState: FeaturesState = {
  hasUsedResumeGenerator: false,
};

export const { reducer: featuresReducer, actions: featuresActions } = createSlice({
  name: 'features',
  initialState,
  reducers: {
    useResumeGenerator: state => {
      state.hasUsedResumeGenerator = true;
    },
  },
});

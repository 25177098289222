import { useQuery } from '@tanstack/react-query';
import { useSelector } from 'react-redux';
import { z } from 'zod';

import { formSchema } from 'src/Containers/CandidateResume/CandidateResume.schema';
import { getBrandCode } from 'src/Redux/Perimeter/Selectors';
import { ResumeGeneratorService } from 'src/Services/API';
import { fetchFile } from 'src/Utils/files';

import { QueryKeys } from '../types';

export const usePreviewCandidateResume = (
  candidateId: string,
  formState: z.infer<typeof formSchema>
) => {
  const brandCode = useSelector(getBrandCode);

  return useQuery(
    [QueryKeys.previewCandidateResume, candidateId, formState],
    async () => {
      const isCvAnonymous =
        !formState.employee.address &&
        !formState.employee.contactInfo &&
        !formState.employee.identity;

      const { url } = await fetchFile(
        ResumeGeneratorService.resumeGeneratorControllerGetCandidateResume,
        {
          candidateId,
          body: {
            qualifications: formState.qualifications,
            businessesSkills: formState.businessSkills,
            behaviourSkills: formState.behaviorSkills,
            experiences: formState.experiences,
            diplomas: formState.degrees.diplomas,
            tests: formState.degrees.tests,
            habilitations: formState.degrees.habilitations,
            drivingLicences: formState.degrees.drivingLicences,
            employeeData: formState.employee,
            description: formState.description,
            brandCode,
            isCvAnonymous,
            osmoseUpload: false,
          },
        }
      );
      return url;
    },
    {
      cacheTime: Infinity,
      staleTime: Infinity,
    }
  );
};

import { Recherche } from '@randstad-lean-mobile-factory/react-assets/dist/illustrations';
import {
  Button,
  CardCheckable,
  CardContent,
  Loader,
} from '@randstad-lean-mobile-factory/react-components-core';
import moment from 'moment';
import { useMemo } from 'react';
import { useController } from 'react-hook-form';
import { z } from 'zod';

import { ErrorMessage } from 'src/Components/ErrorMessage';
import { useFetchCandidateETests } from 'src/Hooks/CandidateR2/useFetchCandidateETests';
import { useFetchCandidateDiplomas } from 'src/Hooks/Candidates/useFetchCandidateDiplomas';
import { useFetchCandidateSkills } from 'src/Hooks/Candidates/useFetchCandidateSkills';
import { useFetchCandidateTests } from 'src/Hooks/Candidates/useFetchCandidateTests';
import { CandidateETests, CandidateTests, DrivingLicenceEntity } from 'src/Services/API';
import { toDate } from 'src/Utils/moment';
import { pluralFormat } from 'src/Utils/pluralFormat';

import { formSchema } from '../CandidateResume.schema';

import styles from './Degrees.module.scss';
import { DegreesProps } from './Degrees.types';

export const Degrees = ({ candidateId, control }: DegreesProps) => {
  const { field } = useController({ control, name: 'degrees' });

  const {
    data: candidateDiplomas,
    isError: candidateDiplomasError,
    refetch: refetchCandidateDiplomas,
  } = useFetchCandidateDiplomas(candidateId);
  const {
    data: candidateTests,
    isError: candidateTestsError,
    refetch: refetchCandidateTests,
  } = useFetchCandidateTests(candidateId);
  const {
    data: candidateETests,
    isError: candidateETestsError,
    refetch: refetchCandidateETests,
  } = useFetchCandidateETests(candidateId);
  const {
    data: candidateSkills,
    isError: candidateSkillsError,
    refetch: refetchCandidateSkills,
  } = useFetchCandidateSkills(candidateId);

  const filteredDiplomas = useMemo(
    () =>
      candidateDiplomas
        ?.filter(
          diploma =>
            !candidateSkills?.habilitations?.some(
              habilitation => habilitation.id === diploma.diplomaId
            )
        )
        .map(diploma => ({
          ...diploma,
          formationName: diploma.formationName
            .split(':')
            .map((part, index) => (index === 0 ? part.toUpperCase() : part.toLowerCase()))
            .join(':'),
          obtentionDate: (diploma.obtentionDate as unknown as string)?.slice(3),
        })),
    [candidateDiplomas, candidateSkills]
  );

  const candidateHabilitations = useMemo(
    () =>
      candidateSkills?.habilitations?.map(habilitation => ({
        ...habilitation,
        label: habilitation.label
          .split(':')
          .map((part, index) => (index === 0 ? part.toUpperCase() : part.toLowerCase()))
          .join(':'),
      })),
    [candidateSkills]
  );

  const drivingLicences = useMemo(
    () =>
      candidateSkills?.drivingLicences?.filter(
        (licence): licence is DrivingLicenceEntity & { label: string } => Boolean(licence.label)
      ),
    [candidateSkills?.drivingLicences]
  );

  const isSelected = (item: string, category: keyof z.infer<typeof formSchema>['degrees']) =>
    field.value[category].includes(item);

  const toggleSelected = (item: string, category: keyof z.infer<typeof formSchema>['degrees']) => {
    if (isSelected(item, category))
      field.onChange({
        ...field.value,
        [category]: field.value[category].filter(it => it !== item),
      });
    else
      field.onChange({
        ...field.value,
        [category]: [...field.value[category], item],
      });
  };

  const isError =
    candidateDiplomasError || candidateTestsError || candidateETestsError || candidateSkillsError;

  const isSuccess = candidateDiplomas && candidateTests && candidateETests && candidateSkills;

  return (
    <div className={styles.container}>
      <div className={styles.titleContainer}>
        <p>sélectionnez les diplômes, tests, habilitations et permis à mettre en avant</p>
      </div>
      {isError ? (
        <>
          <ErrorMessage message="Une erreur est survenue dans la récupération des données" />
          <div>
            <Button.Medium
              type="button"
              onClick={() => {
                if (!candidateDiplomas) refetchCandidateDiplomas();
                if (!candidateTests) refetchCandidateTests();
                if (!candidateETests) refetchCandidateETests();
                if (!candidateSkills) refetchCandidateSkills();
              }}
            >
              réessayer
            </Button.Medium>
          </div>
        </>
      ) : !isSuccess ? (
        <Loader heightInRem={2} darkMode />
      ) : (filteredDiplomas?.length ?? 0) +
          candidateTests.length +
          candidateETests.length +
          (candidateHabilitations?.length ?? 0) +
          (drivingLicences?.length ?? 0) >
        0 ? (
        <div className={styles.cardsContainer}>
          {filteredDiplomas && filteredDiplomas.length > 0 && (
            <div className={styles.categoryContainer}>
              <div className={styles.categoryHeader}>
                <div>{pluralFormat(filteredDiplomas.length, 'diplôme')}</div>
                <div className={styles.categorySelectedCounter}>
                  {pluralFormat(field.value.diplomas.length, 'sélectionné')}
                </div>
              </div>
              {filteredDiplomas.map(diploma => (
                <CardCheckable
                  key={diploma.diplomaId}
                  color="beige"
                  checked={isSelected(diploma.diplomaId, 'diplomas')}
                  onChange={() => toggleSelected(diploma.diplomaId, 'diplomas')}
                >
                  <CardContent
                    title={diploma.formationName}
                    supportingLines={[
                      diploma.obtentionDate && `date d'obtention : ${diploma.obtentionDate}`,
                      diploma.validityDate && `date de validité : ${diploma.validityDate}`,
                    ].filter(Boolean)}
                    titleClassName={styles.itemTitle}
                  />
                </CardCheckable>
              ))}
            </div>
          )}
          {candidateTests.length + candidateETests.length > 0 && (
            <div className={styles.categoryContainer}>
              <div className={styles.categoryHeader}>
                <div>{pluralFormat(candidateTests.length + candidateETests.length, 'test')}</div>
                <div className={styles.categorySelectedCounter}>
                  {pluralFormat(field.value.tests.length, 'sélectionné')}
                </div>
              </div>
              {candidateTests
                .filter((test): test is CandidateTests & { id: string; label: string } =>
                  Boolean(test.id && test.label)
                )
                .map(test => (
                  <CardCheckable
                    key={test.id}
                    color="beige"
                    checked={isSelected(test.id, 'tests')}
                    onChange={() => toggleSelected(test.id, 'tests')}
                  >
                    <CardContent
                      title={test.label}
                      supportingLines={[
                        test.completedOn &&
                          `date d'obtention : ${moment(toDate(test.completedOn)).format('L')}`,
                      ].filter(Boolean)}
                      titleClassName={styles.itemTitle}
                    />
                  </CardCheckable>
                ))}
              {candidateETests
                .filter((test): test is CandidateETests & { id: string; label: string } =>
                  Boolean(test.id && test.label)
                )
                .map(test => (
                  <CardCheckable
                    key={test.id}
                    color="beige"
                    checked={isSelected(test.id, 'tests')}
                    onChange={() => toggleSelected(test.id, 'tests')}
                  >
                    <CardContent
                      title={test.label}
                      supportingLines={[
                        test.completedOn &&
                          `date d'obtention : ${moment(toDate(test.completedOn)).format('L')}`,
                      ].filter(Boolean)}
                      titleClassName={styles.itemTitle}
                    />
                  </CardCheckable>
                ))}
            </div>
          )}
          {candidateHabilitations && candidateHabilitations.length > 0 && (
            <div className={styles.categoryContainer}>
              <div className={styles.categoryHeader}>
                <div>{pluralFormat(candidateHabilitations.length, 'habilitation')}</div>
                <div className={styles.categorySelectedCounter}>
                  {pluralFormat(field.value.habilitations.length, 'sélectionné')}
                </div>
              </div>
              {candidateHabilitations.map(habilitation => (
                <CardCheckable
                  key={habilitation.id}
                  color="beige"
                  checked={isSelected(habilitation.id, 'habilitations')}
                  onChange={() => toggleSelected(habilitation.id, 'habilitations')}
                >
                  <CardContent
                    title={habilitation.label}
                    supportingLines={[
                      habilitation.obtentionDate &&
                        `date d'obtention : ${moment(toDate(habilitation.obtentionDate)).format(
                          'L'
                        )}`,
                      `date de validité: ${moment(toDate(habilitation.validityEndDate)).format(
                        'L'
                      )}`,
                    ].filter(Boolean)}
                    titleClassName={styles.itemTitle}
                  />
                </CardCheckable>
              ))}
            </div>
          )}
          {drivingLicences && drivingLicences.length > 0 && (
            <div className={styles.categoryContainer}>
              <div className={styles.categoryHeader}>
                <div>{drivingLicences.length} permis</div>
                <div className={styles.categorySelectedCounter}>
                  {pluralFormat(field.value.drivingLicences.length, 'sélectionné')}
                </div>
              </div>
              {drivingLicences.map(drivingLicence => (
                <CardCheckable
                  key={drivingLicence.id}
                  color="beige"
                  checked={isSelected(drivingLicence.id, 'drivingLicences')}
                  onChange={() => toggleSelected(drivingLicence.id, 'drivingLicences')}
                >
                  <CardContent
                    title={`permis ${drivingLicence.id} (${drivingLicence.label.toLowerCase()})`}
                    supportingLines={[
                      drivingLicence.obtentionDate &&
                        `date d'obtention : ${moment(drivingLicence.obtentionDate).format('L')}`,
                      drivingLicence.validityEndDate &&
                        `date de validité : ${moment(drivingLicence.validityEndDate).format('L')}`,
                    ].filter(Boolean)}
                    titleClassName={styles.itemTitle}
                  />
                </CardCheckable>
              ))}
            </div>
          )}
        </div>
      ) : (
        <div className={styles.noInfo}>
          <Recherche />
          <span>
            désolé,
            <br />
            aucune information renseignée
          </span>
        </div>
      )}
    </div>
  );
};

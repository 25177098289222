import { ServerErrorYellow } from '@randstad-lean-mobile-factory/react-assets/dist/illustrations';
import { Loader, Tab, Tabs } from '@randstad-lean-mobile-factory/react-components-core';
import { useHistory, useParams } from 'react-router';

import { TopBar } from 'src/Components/TopBar';
import { useFetchCandidateDetailsFromURL } from 'src/Hooks/Candidates/useFetchCandidateDetailsFromURL';
import { useFetchCandidateInterviewsIdAndStatus } from 'src/Hooks/Candidates/useFetchCandidateInterviewsIdAndStatus';
import { useFetchCandidateR1FromURL } from 'src/Hooks/Candidates/useFetchCandidateR1FromURL';
import {
  FormStatusContext,
  useFormNavigationContextElements,
} from 'src/Hooks/Navigation/useFormNavigationContextElements';
import { getCandidateFileUrl } from 'src/Hooks/Navigation/useOpenCandidateFile';
import { FETCH_STATUS } from 'src/Redux/Types';
import { mergeFetchStatus, toFetchStatus } from 'src/Services/ReactQuery';
import { getRecruitingEditionRoute } from 'src/Services/Routing';
import { pluralFormat } from 'src/Utils/pluralFormat';

import NavigationWarningModal from '../Modals/NavigationWarningModal';

import CandidateRecruitingForm from './CandidateRecruitingForm';
import styles from './CandidateRecruitingProcess.module.scss';
import { getNavigationStructure } from './CandidateRecruitingProcess.types';
import RecruitingSideBar from './RecruitingSideBar';

const CandidateRecruitingProcess = () => {
  const history = useHistory<{ origin?: string } | undefined>();
  const params = useParams<{
    id: string;
    firstSection: 'R1' | 'R2';
    candidateId: string;
    secondSection: string;
  }>();
  const candidateDetailsQuery = useFetchCandidateDetailsFromURL();
  const candidateR1Query = useFetchCandidateR1FromURL();
  const recruitingStatusQuery = useFetchCandidateInterviewsIdAndStatus(params.candidateId);
  const fetchStatus = mergeFetchStatus(
    toFetchStatus(candidateR1Query),
    toFetchStatus(recruitingStatusQuery)
  );
  const navContextElement = useFormNavigationContextElements();

  return (
    <FormStatusContext.Provider
      value={{
        hasFormBeenTouched: navContextElement.hasFormBeenTouched,
        setHasFormBeenTouched: navContextElement.setHasFormBeenTouched,
        destination: navContextElement.destination,
        openNavWarningModal: navContextElement.openNavWarningModal,
        closeNavWarningModal: navContextElement.closeNavWarningModal,
        handleSubmit: navContextElement.handleSubmit,
        updateValidationStatus: navContextElement.updateValidationStatus,
        fetchStatus: navContextElement.fetchStatus,
        setFetchStatus: navContextElement.setFetchStatus,
        navWarningModalState: navContextElement.navWarningModalState,
        formState: navContextElement.formState,
        setFormState: navContextElement.setFormState,
      }}
    >
      <TopBar
        goBackTo={getCandidateFileUrl({ candidateId: params.candidateId })}
        title="entretiens"
      ></TopBar>
      <div className={styles.container}>
        <NavigationWarningModal
          open={navContextElement.navWarningModalState}
          onClose={() => navContextElement.closeNavWarningModal()}
        />
        {fetchStatus === FETCH_STATUS.PENDING ? (
          <div className={styles.illustration}>
            <Loader size="large" />
            chargement des détails talent en cours
          </div>
        ) : fetchStatus === FETCH_STATUS.REJECTED ? (
          <div className={styles.illustration}>
            <ServerErrorYellow />
            désolé, une erreur s'est produite lors du chargement
          </div>
        ) : (
          <>
            <div className={styles.tabsContainer}>
              <Tabs
                color="navy"
                size="medium"
                hideSeparator
                value={params.firstSection}
                onChange={section => {
                  const destinationRoute = getRecruitingEditionRoute({
                    candidateId: params.candidateId,
                    section: section,
                    id:
                      section === 'R1'
                        ? recruitingStatusQuery.data?.r1Interview?.interviewId
                        : recruitingStatusQuery.data?.r2Interview?.interviewId,
                  });
                  if (navContextElement.hasFormBeenTouched) {
                    navContextElement.openNavWarningModal({ destination: destinationRoute });
                    navContextElement.setFetchStatus(FETCH_STATUS.FULFILLED);
                  } else {
                    history.replace(destinationRoute);
                  }
                }}
              >
                {Object.entries(getNavigationStructure(recruitingStatusQuery.data)).map(
                  ([key, section]) => (
                    <Tab key={key} value={key}>
                      {section.name}
                    </Tab>
                  )
                )}
              </Tabs>
            </div>
            <div className={styles.tabContainer}>
              <div className={styles.sideBarContainer}>
                <div className={styles.candidateName}>
                  <p>vous êtes sur le profil de :</p>
                  {candidateDetailsQuery.data?.firstName} {candidateDetailsQuery.data?.name}
                </div>
                <div className={styles.candidateContract}>
                  {candidateDetailsQuery.data?.statusCode ?? 'type de contrat non renseigné'} |{' '}
                  {pluralFormat(candidateDetailsQuery.data?.nbHoursWorked ?? 0, 'heure')}
                </div>
                <RecruitingSideBar />
              </div>
              <CandidateRecruitingForm />
            </div>
          </>
        )}
      </div>
    </FormStatusContext.Provider>
  );
};

export default CandidateRecruitingProcess;

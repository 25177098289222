import { useMutation, UseMutationOptions, useQueryClient } from '@tanstack/react-query';
import { AxiosError } from 'axios';
import ReactGA from 'react-ga4';
import { useSelector } from 'react-redux';
import { z } from 'zod';

import { formSchema } from 'src/Containers/CandidateResume/CandidateResume.schema';
import { getBrandCode, getPerimeter } from 'src/Redux/Perimeter/Selectors';
import { ResumeGeneratorService } from 'src/Services/API';
import { downloadFile, fetchFile } from 'src/Utils/files';

import { MutationKeys, QueryKeys } from '../types';

export const useUploadCandidateResume = (
  candidateId: string,
  extraOptions?: UseMutationOptions<void, AxiosError, z.infer<typeof formSchema>>
) => {
  const brandCode = useSelector(getBrandCode);
  const perimeter = useSelector(getPerimeter);
  const queryClient = useQueryClient();

  return useMutation(
    [MutationKeys.uploadCandidateResume, candidateId],
    async formState => {
      const isCvAnonymous =
        !formState.employee.address &&
        !formState.employee.contactInfo &&
        !formState.employee.identity;

      downloadFile(
        await fetchFile(ResumeGeneratorService.resumeGeneratorControllerGetCandidateResume, {
          candidateId,
          body: {
            qualifications: formState.qualifications,
            businessesSkills: formState.businessSkills,
            behaviourSkills: formState.behaviorSkills,
            experiences: formState.experiences,
            diplomas: formState.degrees.diplomas,
            tests: formState.degrees.tests,
            habilitations: formState.degrees.habilitations,
            drivingLicences: formState.degrees.drivingLicences,
            employeeData: formState.employee,
            description: formState.description,
            brandCode,
            isCvAnonymous,
            osmoseUpload: true,
          },
        })
      );
    },
    {
      ...extraOptions,
      onSuccess: (data, variables, context) => {
        ReactGA.event('UPLOAD_GENERATED_RESUME', {
          brandCode: brandCode,
          agency: perimeter?.defaultAgencyId,
          regionId: perimeter?.regionId,
          zoneId: perimeter?.zoneId,
        });
        queryClient.invalidateQueries([QueryKeys.fetchCandidateDocuments, candidateId]);
        extraOptions?.onSuccess?.(data, variables, context);
      },
    }
  );
};

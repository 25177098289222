import { AppState, Auth0Provider } from '@auth0/auth0-react';
import { PropsWithChildren, useCallback } from 'react';

export const AuthenticationProvider = ({ children }: PropsWithChildren) => {
  const onRedirectCallback = useCallback((appState?: AppState) => {
    if (appState?.returnTo) window.location.replace(appState.returnTo);
  }, []);

  return (
    <Auth0Provider
      domain={process.env.REACT_APP_AUTH0_DOMAIN ?? ''}
      clientId={process.env.REACT_APP_AUTH0_CLIENT_ID ?? ''}
      authorizationParams={{
        redirectUri: process.env.REACT_APP_AUTH0_REDIRECT_URI ?? '',
        audience: process.env.REACT_APP_AUTH0_AUDIENCE ?? '',
      }}
      onRedirectCallback={onRedirectCallback}
      cacheLocation="localstorage"
    >
      {children}
    </Auth0Provider>
  );
};

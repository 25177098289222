import {
  DocumentPdf,
  Flux,
  Folder,
  Shortcuts,
} from '@randstad-lean-mobile-factory/react-assets/dist/icons';
import { Menu, MenuItem } from '@randstad-lean-mobile-factory/react-components-core';
import { useDispatch } from 'react-redux';
import { useSelector } from 'react-redux';

import { CANDIDATE_STATUS_LABEL } from 'src/Containers/CandidateResults/CandidateResults.types';
import { useOpenOSMResume, useOpenPersonalResume } from 'src/Hooks/Candidates';
import { useOpenCandidateFile } from 'src/Hooks/Navigation/useOpenCandidateFile';
import { useOpenCandidateResume } from 'src/Hooks/Navigation/useOpenCandidateResume';
import { getHasUsedResumeGenerator } from 'src/Redux/Features/Selector';
import { featuresActions } from 'src/Redux/Features/Slice';
import { formatName } from 'src/Utils/formatName';

import styles from './CandidateActions.module.scss';
import { CandidateActionsProps } from './CandidateActions.types';

export const CandidateActions = ({
  candidate,
  isSelecting,
  attachCandidate,
  ...props
}: CandidateActionsProps) => {
  const openOsmResume = useOpenOSMResume({
    onSettled: () => {
      setTimeout(() => openOsmResume.reset(), 500);
    },
  });
  const openPersonalResume = useOpenPersonalResume({
    onSettled: () => {
      setTimeout(() => openPersonalResume.reset(), 500);
    },
  });
  const { openCandidateFile } = useOpenCandidateFile();
  const { openCandidateResume } = useOpenCandidateResume();

  const dispatch = useDispatch();
  const hasUsedResumeGenerator = useSelector(getHasUsedResumeGenerator);

  return (
    <Menu {...props}>
      <MenuItem
        onClick={() =>
          openOsmResume.mutate({
            candidateId: candidate.candidateId ?? '',
            candidateName: formatName(candidate) ?? '',
          })
        }
        mutationStatus={openOsmResume.status}
        keepOpenOnClick
        disabled={candidate.candidateStatus === CANDIDATE_STATUS_LABEL['Candidat']}
      >
        <DocumentPdf /> cv Osmose
      </MenuItem>
      <MenuItem
        onClick={() =>
          openPersonalResume.mutate({
            candidateId: candidate.candidateId ?? '',
            candidateName: formatName(candidate) ?? '',
          })
        }
        mutationStatus={openPersonalResume.status}
        keepOpenOnClick
      >
        <DocumentPdf /> cv talent
      </MenuItem>
      <MenuItem
        onClick={() => {
          dispatch(featuresActions.useResumeGenerator());
          openCandidateResume({ candidateId: candidate.candidateId });
        }}
      >
        <Shortcuts /> générer cv
        {!hasUsedResumeGenerator && <span className={styles.new}>nouveau</span>}
      </MenuItem>
      {!isSelecting && (
        <MenuItem
          onClick={() =>
            openCandidateFile({ candidateId: candidate.candidateId, emitAnalyticsEvent: true })
          }
        >
          <Folder /> dossier talent
        </MenuItem>
      )}
      {isSelecting && (
        <MenuItem onClick={attachCandidate}>
          <Flux /> rattacher à la commande
        </MenuItem>
      )}
    </Menu>
  );
};

import {
  DropDown,
  SegmentedControl,
  WithLightTitle,
} from '@randstad-lean-mobile-factory/react-components-core';
import { Calendrier, Timing } from '@randstad-lean-mobile-factory/react-components-ui-shared';
import { useCallback, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Route, Router, Switch, useHistory, useParams } from 'react-router';

import { getStatsSearchPeriod } from 'src/Redux/InterviewsStats/Selector';
import { interviewsStatsActions } from 'src/Redux/InterviewsStats/Slice';
import { STATS_SEARCH_PERIOD } from 'src/Redux/InterviewsStats/Types';

import styles from './InterviewDashboard.module.scss';
import InterviewStatistics from './InterviewStatistics';
import ZoneSelector from './InterviewStatistics/ZoneSelector';
import InterviewTraceBoards from './InterviewTraceBoards';
import StatusSelector from './InterviewTraceBoards/StatusSelector';
import { InterviewDashboardContext } from './InterviewTraceBoardsContext';
export enum DASHBOARD_MENU {
  STATISTICS = 'statistics',
  FOLLOW = 'follow',
}

const dashboardMenuLabel = {
  [DASHBOARD_MENU.STATISTICS]: 'pilotage',
  [DASHBOARD_MENU.FOLLOW]: 'suivi',
};

const InterviewDashboard = () => {
  const dispatch = useDispatch();
  const params = useParams<{ dashboardMenu: DASHBOARD_MENU }>();
  const history = useHistory();
  const statsSearchPeriod = useSelector(getStatsSearchPeriod);
  const changeStatsSearchPeriod = useCallback(
    (searchPeriod?: STATS_SEARCH_PERIOD) => {
      dispatch(
        interviewsStatsActions.setStatsSearchPeriod(searchPeriod ?? STATS_SEARCH_PERIOD.THREE_MONTH)
      );
    },
    [dispatch]
  );
  const [segmentedControlKey, setSegmentedControlKey] = useState(0);
  return (
    <div className={styles.container}>
      <InterviewDashboardContext.Provider
        value={{
          segmentedControlKey: segmentedControlKey,
          setSegmentedControlKey: setSegmentedControlKey,
        }}
      >
        <div className={styles.section}>
          <div className={styles.title}>entretiens</div>
        </div>
        <div className={styles.menuBar}>
          <SegmentedControl
            key={segmentedControlKey}
            className={styles.segmentedControl}
            labelClassName={styles.segmentedLabels}
            controls={Object.values(DASHBOARD_MENU)}
            selected={params.dashboardMenu}
            getValue={dashboardMenuItem => dashboardMenuLabel[dashboardMenuItem]}
            onSelectionChange={dashboardMenuItem => {
              history.push(dashboardMenuItem);
            }}
          />
          <div className={styles.filterContainers}>
            <ZoneSelector />
            <WithLightTitle title="depuis" className={styles.dropDownContainer}>
              <DropDown
                className={styles.dropDown}
                items={Object.values(STATS_SEARCH_PERIOD)}
                triggerIcon={<Calendrier className={styles.calendarIcon} />}
                placeholder="choisir une période"
                selectedItem={statsSearchPeriod}
                onSelectItem={changeStatsSearchPeriod}
                keyValueExtractor={(item: STATS_SEARCH_PERIOD) => {
                  return { key: item, value: item, icon: <Timing /> };
                }}
              />
            </WithLightTitle>
            {params.dashboardMenu === DASHBOARD_MENU.FOLLOW && <StatusSelector />}
          </div>
        </div>
        <div className={styles.content}>
          <Router history={history}>
            <Switch>
              <Route exact path="/interviews/follow" component={InterviewTraceBoards} />
              <Route exact path="/interviews/statistics" component={InterviewStatistics} />
            </Switch>
          </Router>
        </div>
      </InterviewDashboardContext.Provider>
    </div>
  );
};

export default InterviewDashboard;

import { useAuth0 } from '@auth0/auth0-react';
import { useQuery, UseQueryOptions } from '@tanstack/react-query';
import { AxiosError } from 'axios';
import { useDispatch, useSelector } from 'react-redux';

import { getStatsSelectedAgencies } from 'src/Redux/InterviewsStats/Selector';
import { interviewsStatsActions } from 'src/Redux/InterviewsStats/Slice';
import { perimeterActions } from 'src/Redux/Perimeter/Slice';
import { ConsultantPerimeter, ConsultantService } from 'src/Services/API';

import { QueryKeys } from '../types';

export const useFetchPerimeter = (
  extraOptions?: Omit<
    UseQueryOptions<ConsultantPerimeter, AxiosError, ConsultantPerimeter, [QueryKeys]>,
    'queryKey' | 'queryFn'
  >
) => {
  const { isAuthenticated } = useAuth0();

  const dispatch = useDispatch();
  const statsUnits = useSelector(getStatsSelectedAgencies);

  return useQuery(
    [QueryKeys.fetchPerimeter],
    async () => {
      try {
        const perimeter = await ConsultantService.consultantsControllerGetPerimeter();
        dispatch(perimeterActions.setPerimeter(perimeter));
        if (statsUnits.length === 0) {
          dispatch(
            interviewsStatsActions.setStatsSelectedAgencies(
              perimeter.defaultAgencyId ? [perimeter.defaultAgencyId] : []
            )
          );
        }
        return perimeter;
      } catch (error) {
        dispatch(perimeterActions.clearPerimeter());
        throw error;
      }
    },
    {
      retry: false,
      enabled: isAuthenticated,
      refetchOnWindowFocus: false,
      cacheTime: Infinity,
      staleTime: Infinity,
      ...extraOptions,
    }
  );
};

import {
  Button,
  Modal,
  ModalActions,
  ModalContent,
} from '@randstad-lean-mobile-factory/react-components-core';
import moment from 'moment';

import { Success } from 'src/Assets';

import styles from './UploadSuccessModal.module.scss';
import { Props } from './UploadSuccessModal.types';

export const UploadSuccessModal = ({ open, onClose }: Props) => {
  return (
    <Modal size="medium" open={open} onClose={onClose}>
      <ModalContent header={<Success />} title="message d'information">
        <div className={styles.date}>{moment().format('[le ]L[ à ]HH:mm')}</div>
        <div className={styles.text}>
          Votre document a bien été ajouté aux documents Osmose dans la catégorie "Autre CV".
          <br />
          Celui-ci restera disponible pendant au moins 3 mois.
        </div>
      </ModalContent>
      <ModalActions side="right">
        <Button.Primary onClick={onClose}>valider</Button.Primary>
      </ModalActions>
    </Modal>
  );
};

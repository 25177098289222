import { RandstadAppsPopup, Button } from '@randstad-lean-mobile-factory/react-components-core';
import { Menu } from '@randstad-lean-mobile-factory/react-components-ui-shared';
import classnames from 'classnames';
import React, { useState } from 'react';
import ReactGA from 'react-ga4';
import { useSelector } from 'react-redux';

import { useIsProductionEnvironment } from 'src/Hooks/Environment/useIsProductionEnvironment';
import { getBrandCode, getCurrentBrand, getPerimeter } from 'src/Redux/Perimeter/Selectors';

import styles from './Apps.module.scss';

export const Apps = () => {
  const { isProd } = useIsProductionEnvironment();
  const [isOpened, setIsOpened] = useState(false);
  const brand = useSelector(getCurrentBrand);
  const brandCode = useSelector(getBrandCode);
  const perimeter = useSelector(getPerimeter);
  return (
    <RandstadAppsPopup
      isProd={isProd}
      brandCode={brand?.brand?.code}
      anchorOrigin={{ horizontal: 'right', vertical: 'bottom' }}
      transformOrigin={{ horizontal: 'right', vertical: 'top' }}
      onOpen={() => setIsOpened(true)}
      onClose={() => setIsOpened(false)}
      onItemClick={name => {
        ReactGA.event(`Navigation vers une autre application`, {
          name,
          brandCode,
          agency: perimeter?.defaultAgencyId,
          regionId: perimeter?.regionId,
          zoneId: perimeter?.zoneId,
        });
      }}
      trigger={
        <div>
          <Button.Tertiary className={classnames(styles.button, { [styles.openButton]: isOpened })}>
            <Menu className={styles.buttonIcon} />
          </Button.Tertiary>
        </div>
      }
    />
  );
};

import { WithLightTitle } from '@randstad-lean-mobile-factory/react-components-core';

import styles from './ContactModes.module.scss';
import { ContactModesProps } from './ContactModes.types';
import { getContactModes } from './ContactModes.utils';

const ContactModes = ({ candidate }: ContactModesProps) => {
  const candidateContactModes = getContactModes(candidate);
  return (
    <div className={styles.container}>
      <div className={styles.title}>canaux de communication</div>
      {candidateContactModes.length > 0 ? (
        <WithLightTitle
          title={`${candidateContactModes.length} ${
            candidateContactModes.length > 1 ? 'canaux sélectionnés' : 'canal sélectionné'
          }`}
        >
          {candidateContactModes.join(', ')}
        </WithLightTitle>
      ) : (
        <div>aucun canal de communication</div>
      )}
    </div>
  );
};

export default ContactModes;

import { useAuth0 } from '@auth0/auth0-react';
import { Randstad } from '@randstad-lean-mobile-factory/react-assets/dist/brandLogos';
import { Danger } from '@randstad-lean-mobile-factory/react-assets/dist/icons';
import { Recrutlive } from '@randstad-lean-mobile-factory/react-assets/dist/logos';
import { Button } from '@randstad-lean-mobile-factory/react-components-core';

import { useFetchPerimeter } from 'src/Hooks/Consultants/useFetchPerimeter';

import styles from './Login.module.scss';

export const Login = () => {
  const {
    isAuthenticated,
    isLoading: isAuthLoading,
    loginWithRedirect,
    error: authError,
  } = useAuth0();

  const {
    data: perimeter,
    isLoading: isPerimeterLoading,
    error: perimeterError,
    refetch: refetchPerimeter,
  } = useFetchPerimeter();

  const isNetworkError =
    perimeterError?.message === 'Network Error' || perimeterError?.response?.status === 502;

  return (
    <div className={styles.container}>
      <div className={styles.content}>
        <Recrutlive size="large" />
        <div className={styles.title} data-theme="dark">
          Bienvenue
          <br />
          sur l'application recrut'live
        </div>
        <div className={styles.description} data-theme="dark">
          votre service online de suivi de votre activité
        </div>
        {authError && (
          <>
            <div className={styles.error}>
              <Danger size="xlarge" />
              <div>
                Une erreur s'est produite lors de l'authentification. Si l'erreur persiste,
                contactez l'assistance.
              </div>
            </div>
            <div className={styles.error}>
              Message d'erreur :
              <br />
              {authError?.message}
            </div>
          </>
        )}
        {isNetworkError ? (
          <div className={styles.error}>
            <Danger size="xlarge" />
            <div>
              Impossible de contacter le serveur de recrutlive. Vérifiez le statut de votre
              connexion internet.
              <br />
              Si l'erreur persiste, contactez l'assistance.
            </div>
          </div>
        ) : (
          perimeterError && (
            <div className={styles.error}>
              <Danger size="xlarge" />
              <div>
                Une erreur s'est produite lors de la récupération de votre périmètre. Vérifiez que
                votre périmètre a bien été renseigné dans workday.
              </div>
            </div>
          )
        )}
        <Randstad color="white" className={styles.brandLogo} />
        {!isAuthenticated ? (
          <Button
            onClick={() => loginWithRedirect({ appState: { returnTo: window.location.href } })}
            mutationStatus={isAuthLoading ? 'loading' : undefined}
          >
            se connecter
          </Button>
        ) : !perimeter ? (
          <Button
            onClick={() => refetchPerimeter()}
            mutationStatus={isPerimeterLoading ? 'loading' : undefined}
          >
            réessayer
          </Button>
        ) : null}
      </div>
    </div>
  );
};

import { CandidateExperience } from 'src/Services/API';

export enum SORT_ORDER {
  ASCENDING = 'croissant',
  DESCENDING = 'decroissant',
}

export interface GroupedExperience {
  label: string;
  companyName: string;
  experiences: CandidateExperience[];
  isInternalExperiences: boolean;
}

export enum SORT_OPTIONS {
  NAME = 'NAME',
  YEAR = 'YEAR',
  HOURS = 'HOURS',
  QUALIFICATION = 'QUALIFICATION',
}

export interface ExperienceProps {
  internal: boolean;
}

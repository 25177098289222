import {
  BadgeDeprecated,
  CardCheckable,
  CardContent,
  TextInput,
  WithLightTitle,
} from '@randstad-lean-mobile-factory/react-components-core';
import {
  CircleError,
  CloseOld,
  PlusInCircle,
} from '@randstad-lean-mobile-factory/react-components-ui-shared';
import { useState } from 'react';
import { useController } from 'react-hook-form';

import styles from './BehaviorSkills.module.scss';
import { BehaviorSkillsProps, behaviorSkillsSuggestions } from './BehaviorSkills.types';

export const BehaviorSkills = ({ control }: BehaviorSkillsProps) => {
  const { field } = useController({
    name: 'behaviorSkills',
    control,
  });

  const [inputValue, setInputValue] = useState('');

  const handleInputChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    setInputValue(e.target.value);
  };

  const handleAddItem = () => {
    if (inputValue.trim() !== '') {
      field.onChange([...field.value, inputValue.trim()]);
      setInputValue('');
    }
  };

  const handleRemoveItem = (index: number) => {
    field.onChange(field.value.filter((_: string, i: number) => i !== index));
  };

  return (
    <div className={styles.container}>
      <h3 className={styles.title}>
        sélectionnez les compétences comportementales à mettre en avant
      </h3>
      <WithLightTitle
        title="ajouter des compétences comportementales (10 max)"
        titleClassName={styles.subTitle}
        className={styles.searchBar}
      >
        <TextInput
          value={inputValue}
          onChange={handleInputChange}
          onKeyDown={event => {
            if (event.key === 'Enter') {
              handleAddItem();
              event.preventDefault();
            }
          }}
          rightIcon={
            <>
              <CloseOld className={styles.cross} onClick={() => setInputValue('')} />
              <PlusInCircle className={styles.plus} onClick={handleAddItem} />
            </>
          }
          disabled={field.value.length >= 10}
        />
      </WithLightTitle>
      {field.value.filter((item: string) => !behaviorSkillsSuggestions.includes(item)).length >
        0 && (
        <WithLightTitle title="compétences ajoutées" titleClassName={styles.subTitle}>
          {field.value
            .filter((item: string) => !behaviorSkillsSuggestions.includes(item))
            .map((item: string, index: number) => (
              <BadgeDeprecated
                className={styles.badge}
                key={index}
                value={item}
                rightIcon={
                  <CircleError
                    className={styles.deleteIcon}
                    onClick={() => handleRemoveItem(index)}
                  />
                }
              />
            ))}
        </WithLightTitle>
      )}
      <WithLightTitle
        title="suggestions de compétences comportementales"
        titleClassName={styles.subTitle}
      >
        <div className={styles.gridContainer}>
          {behaviorSkillsSuggestions.map((skill, idx) => (
            <CardCheckable
              key={idx}
              color="beige"
              checked={field.value.includes(skill)}
              onChange={checked => {
                if (checked) {
                  field.onChange([...field.value, skill]);
                } else {
                  field.onChange(
                    field.value.filter((selectedSkill: string) => selectedSkill !== skill)
                  );
                }
                field.onBlur();
              }}
            >
              <CardContent title={skill} titleClassName={styles.cardTitle} />
            </CardCheckable>
          ))}
        </div>
      </WithLightTitle>
    </div>
  );
};

import { Close } from '@randstad-lean-mobile-factory/react-assets/dist/icons';
import { Badge, Button } from '@randstad-lean-mobile-factory/react-components-core';
import { DocumentFile, Refresh } from '@randstad-lean-mobile-factory/react-components-ui-shared';
import classNames from 'classnames';
import { groupBy } from 'lodash-es';
import moment from 'moment';
import React, { useEffect, useMemo, useState } from 'react';
import ContentLoader from 'react-content-loader';

import { ErrorMessage } from 'src/Components/ErrorMessage';
import { useFetchDocument } from 'src/Hooks/Candidates';
import { useFetchCandidateDocumentsFromURL } from 'src/Hooks/Candidates/useFetchCandidateDocumentsFromURL';

import styles from './Documents.module.scss';

const Documents = () => {
  const documentsQuery = useFetchCandidateDocumentsFromURL();
  const documentsByCategory = groupBy(documentsQuery.data, doc => doc.category);
  const enumCategories = Object.keys(documentsByCategory).sort((a, b) => a.localeCompare(b));

  const [selectedCategories, setSelectedCategories] = useState<string[]>();
  useEffect(() => {
    if (enumCategories.length > 0 && !selectedCategories) {
      setSelectedCategories(enumCategories);
    }
  }, [enumCategories, selectedCategories]);

  const documentsToDisplay = useMemo(() => {
    return selectedCategories?.reduce((filteredDocuments, category) => {
      return filteredDocuments?.concat(documentsByCategory[category] || []);
    }, [] as typeof documentsQuery.data);
  }, [selectedCategories, documentsQuery, documentsByCategory]);

  const { mutate, isLoading, variables } = useFetchDocument();
  return (
    <div>
      <div className={styles.title}>liste des documents rattachés au dossier</div>
      {documentsQuery.isLoading &&
        new Array(5).fill(null).map((_, idx) => (
          <ContentLoader key={idx} height="3rem" width="100%" uniqueKey="document">
            <rect x="2%" y="20" rx="4" ry="4" width="100%" height="20" />
          </ContentLoader>
        ))}
      {documentsQuery.isError && (
        <ErrorMessage message="une erreur est survenue dans la récupération des documents" />
      )}
      {documentsQuery.isSuccess && (
        <>
          <Button
            variant="secondary"
            size="small"
            className={classNames(styles.button, {
              [styles.triggeredButton]: selectedCategories?.length === enumCategories.length,
            })}
            selected={selectedCategories?.length === enumCategories.length}
            onClick={() => {
              if (selectedCategories?.length !== enumCategories.length) {
                setSelectedCategories(enumCategories);
              }
            }}
          >
            toutes les catégories
            <Badge
              color={selectedCategories?.length === enumCategories.length ? 'neutral' : 'blue'}
              saturation={selectedCategories?.length === enumCategories.length ? 'medium' : 'full'}
            >
              {documentsQuery.data.length}
            </Badge>
            {selectedCategories?.length === enumCategories.length && (
              <Close className={styles.close} onClick={() => setSelectedCategories([])} />
            )}
          </Button>
          {enumCategories.map(category => (
            <Button
              variant="secondary"
              size="small"
              className={classNames(styles.button, {
                [styles.triggeredButton]: selectedCategories?.includes(category),
              })}
              selected={selectedCategories?.includes(category)}
              onClick={() => {
                if (!selectedCategories?.includes(category)) {
                  setSelectedCategories([...(selectedCategories || []), category]);
                }
              }}
            >
              {category}
              <Badge
                color={selectedCategories?.includes(category) ? 'neutral' : 'blue'}
                saturation={selectedCategories?.includes(category) ? 'medium' : 'full'}
              >
                {documentsByCategory[category].length}
              </Badge>
              {selectedCategories?.includes(category) && (
                <Close
                  className={styles.close}
                  onClick={() =>
                    setSelectedCategories(selectedCategories.filter(c => c !== category))
                  }
                />
              )}
            </Button>
          ))}
          {documentsToDisplay?.map(document => (
            <div className={styles.documentItem} key={document.id}>
              <DocumentFile />
              <div
                className={styles.documentName}
                onClick={() => mutate({ documentId: document.id ?? '' })}
              >
                {document.type}
              </div>
              {isLoading && variables?.documentId === document.id && (
                <Refresh className={styles.loading} />
              )}
              <div className={styles.uploadDate}>{`uploadé le ${moment(
                document.attachmentDate
              ).format('L')}`}</div>
            </div>
          ))}
        </>
      )}
    </div>
  );
};

export default Documents;

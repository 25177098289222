import { WithLightTitle } from '@randstad-lean-mobile-factory/react-components-core';
import React from 'react';

import styles from './Visualization.module.scss';
import { Props } from './Visualization.types';

export const ifEmpty = (text?: string) => {
  return text ?? 'non renseigné';
};

const Visualization = ({ candidate }: Props) => (
  <div className={styles.grid}>
    <WithLightTitle title="civilité">{candidate?.gender === '1' ? 'M.' : 'Mme'}</WithLightTitle>
    <WithLightTitle title="nom">{ifEmpty(candidate?.name)}</WithLightTitle>
    <WithLightTitle title="prénom">{ifEmpty(candidate?.firstName)}</WithLightTitle>
    <WithLightTitle title="email">
      {candidate?.email ? (
        <a href={`mailto:${candidate?.email}`} className={styles.link}>
          {candidate?.email}
        </a>
      ) : (
        'non renseigné'
      )}
    </WithLightTitle>
    {[candidate?.phone1, candidate?.phone2]
      .filter(phone => phone !== undefined)
      .map((phone, idx) => (
        <WithLightTitle key={`phone-${idx}`} title={`téléphone ${idx + 1}`}>
          <a href={`tel:${phone}`} className={styles.link}>
            {phone}
          </a>
        </WithLightTitle>
      ))}
    <WithLightTitle title="adresse">
      <div>{candidate?.address}</div>
      <div>
        {candidate?.zipCode} {candidate?.city}
      </div>
    </WithLightTitle>
  </div>
);

export default Visualization;

import Step from '@mui/material/Step';
import StepLabel from '@mui/material/StepLabel';
import Stepper from '@mui/material/Stepper';
import { FileDropzone } from '@randstad-lean-mobile-factory/react-components-core';
import classNames from 'classnames';
import React, { useEffect, useState } from 'react';

import { ParsedDocument, useFetchDocumentData } from 'src/Hooks/AddNewTalent/useFetchDocumentData';
import { FETCH_STATUS } from 'src/Redux/Types';
import { JSONData } from 'src/Services/API';
import { toFetchStatus } from 'src/Services/ReactQuery';
import { dropzoneData } from 'src/Utils/dropZoneData';

import PhotoModal from '../Modals/AddNewTalentModals/PhotoModal/PhotoModal.component';

import styles from './AddNewTalent.module.scss';
import {
  AddNewTalentStepperItems,
  AddNewTalentSteps,
  DocumentFile,
  DropzoneItem,
} from './AddNewTalent.types';
import DocumentConfirmation from './DocumentConfirmation/DocumentConfirmation.component';
import FormAddNewTalent from './FormAddNewTalent/FormAddNewTalent.component';
import { FormSteps } from './FormAddNewTalent/FormAddNewTalent.types';
import LoadingDocumentData from './LoadingDocumentData/LoadingDocumentData.component';
import { DOCUMENT_STATUS } from './LoadingDocumentData/LoadingDocumentData.types';
import PDFDocumentViewer from './PDFDocumentViewer/PDFDocumentViewer.component';
import { parserFormatter } from './utils';

const AddNewTalent = () => {
  const [documentFile, setDocumentFile] = useState<DocumentFile>();
  const [parsedDocument, setParsedDocument] = useState<ParsedDocument>();
  const [step, setStep] = useState<AddNewTalentSteps>(AddNewTalentSteps.AddDocuments);
  const [formStep, setFormStep] = useState<FormSteps>(FormSteps.Identity);

  const documentData = useFetchDocumentData(documentFile?.file);
  const fetchStatus = toFetchStatus({ ...documentData, defaultValue: FETCH_STATUS.PENDING });

  const fileToDataUrl = (file: File) => {
    const reader = new FileReader();
    reader.onload = () => {
      setDocumentFile(documentFile => {
        return { ...documentFile, base64File: reader.result as string, file: file };
      });
    };

    file && reader.readAsDataURL(file);
  };

  const hasDocument = documentFile?.file || documentFile?.base64File;

  const setDocumentStatus = (item: number): void => {
    setDocumentFile(documentFile => {
      return { ...documentFile, documentStatus: item };
    });
  };

  const onDocumentDropped = async (items: DropzoneItem[]) => {
    fileToDataUrl(items[0]?.file);
    if (
      !['image/png', 'image/jpg', 'image/jpeg'].some(
        photoType => photoType === items[0]?.file?.type
      )
    ) {
      setDocumentFile(documentFile => {
        return { ...documentFile, file: items[0]?.file };
      });
    }
  };

  useEffect(() => {
    let intervalId: NodeJS.Timeout;
    if (fetchStatus === FETCH_STATUS.FULFILLED) {
      setDocumentStatus(0);
      setParsedDocument(parserFormatter(documentData.data as JSONData[]));
      intervalId = setInterval(() => {
        setDocumentFile(documentFile => {
          let documentStatus = documentFile?.documentStatus ?? 0;
          if (documentStatus >= DOCUMENT_STATUS.FINISHED) {
            clearInterval(intervalId);
            documentStatus = DOCUMENT_STATUS.FINISHED;
            setStep(AddNewTalentSteps.Forms);
          } else {
            documentStatus = documentStatus + 1;
          }
          return {
            ...documentFile,
            documentStatus: documentStatus,
          };
        });
      }, 1000);
    } else {
      setDocumentStatus(0);
    }
    return () => {
      clearInterval(intervalId);
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [fetchStatus, documentFile?.file, documentFile?.base64File]);

  return (
    <div className={styles.container}>
      <div className={styles.responsiveContainer}>
        <div className={styles.title}> nouveau talent</div>
        {!hasDocument && (
          <div className={styles.buttonsContainer}>
            <PhotoModal
              onSuccess={(documentFile: DocumentFile) => {
                setDocumentFile(documentFile);
              }}
            />
          </div>
        )}
        <div className={styles.outerContainer}>
          <div
            className={classNames(styles.leftContainer, {
              [styles.expandedLeftContainer]: step === AddNewTalentSteps.AddDocuments,
            })}
          >
            {step !== AddNewTalentSteps.Forms && step !== AddNewTalentSteps.Conflicts && (
              <div className={styles.stepperContainer}>
                <Stepper activeStep={step} alternativeLabel className={styles.stepper}>
                  {AddNewTalentStepperItems.map((item, index) => {
                    return (
                      <Step key={item.name} completed={index < step}>
                        <StepLabel>{item.title}</StepLabel>
                      </Step>
                    );
                  })}
                </Stepper>
              </div>
            )}
            {step === AddNewTalentSteps.AddDocuments &&
              (hasDocument ? (
                <DocumentConfirmation
                  confirm={() => {
                    setStep(AddNewTalentSteps.LoadingPage);
                    documentData.refetch();
                  }}
                  documentFile={documentFile}
                  deleteDocument={() => {
                    setDocumentFile(undefined);
                  }}
                />
              ) : (
                <FileDropzone
                  {...dropzoneData}
                  items={[] as DropzoneItem[]}
                  onUpload={onDocumentDropped}
                  onDelete={() => {
                    //pass
                  }}
                  onClick={item => {
                    window.open(URL.createObjectURL(item.file));
                  }}
                  containerStyle={{
                    height: 'calc(100% - 2.5rem)',
                    justifyContent: 'center',
                  }}
                />
              ))}
            {hasDocument &&
              step !== AddNewTalentSteps.AddDocuments &&
              (step === AddNewTalentSteps.LoadingPage ? (
                <LoadingDocumentData status={documentFile?.documentStatus} />
              ) : (
                (step === AddNewTalentSteps.Forms || step === AddNewTalentSteps.Conflicts) && (
                  <FormAddNewTalent
                    parsedDocument={parsedDocument}
                    step={step}
                    setStep={setStep}
                    formStep={formStep}
                    setFormStep={setFormStep}
                  />
                )
              ))}
          </div>
          {hasDocument && step !== AddNewTalentSteps.AddDocuments && (
            <div className={styles.rightContainer}>
              {!(formStep === FormSteps.Conflicts || formStep === FormSteps.Confirmation) &&
                (!['image/png', 'image/jpg', 'image/jpeg'].some(
                  photoType => photoType === documentFile?.file?.type
                ) ? (
                  <PDFDocumentViewer
                    // supposes aspect ratio of 1:1.41
                    height={Math.min(window.innerHeight - 214, (window.innerWidth / 2 - 84) * 1.41)}
                    file={documentFile.file as File}
                  />
                ) : (
                  <img src={documentFile?.base64File} className={styles.photo} alt="CV" />
                ))}
            </div>
          )}
        </div>
      </div>
    </div>
  );
};

export default AddNewTalent;

import { Eye } from '@randstad-lean-mobile-factory/react-assets/dist/icons';
import {
  CardClickable,
  CardContent,
  DetailsModal,
} from '@randstad-lean-mobile-factory/react-components-core';
import { useState } from 'react';
import ContentLoader from 'react-content-loader';

import CandidateBusinessModal from 'src/Components/CandidateBusinessModal';
import { ErrorMessage } from 'src/Components/ErrorMessage';
import { useFetchCandidateBusinessesFromURL } from 'src/Hooks/Candidates/useFetchCandidateBusinessesFromURL';
import { CandidateDetailedBusiness } from 'src/Services/API';
import { pluralFormat } from 'src/Utils/pluralFormat';

import styles from './SkillsByJob.module.scss';

const SkillsByJob = () => {
  const businessesQuery = useFetchCandidateBusinessesFromURL();

  const [selectedBusiness, setSelectedBusiness] = useState<CandidateDetailedBusiness>();

  return (
    <div className={styles.list}>
      {businessesQuery.isLoading ? (
        new Array(5).fill(null).map((_, idx) => (
          <ContentLoader key={idx} height="3rem" width="100%" uniqueKey="diploma">
            <rect x="2%" y="20" rx="4" ry="4" width="100%" height="20" />
          </ContentLoader>
        ))
      ) : !businessesQuery.data ? (
        <ErrorMessage message="une erreur est survenue dans la récupération des compétences" />
      ) : (
        businessesQuery.data.map((business, index) => (
          <CardClickable key={index} color="white" onClick={() => setSelectedBusiness(business)}>
            <CardContent
              title={business.label}
              supportingLines={[
                [
                  `pour ce métier : ${pluralFormat(
                    business.candidateBusinessesSkills?.length ?? 0,
                    'compétence'
                  )}`,
                  pluralFormat(business.candidateQualifications?.length ?? 0, 'qualification'),
                ]
                  .join(' | ')
                  .replaceAll(' 0 ', ' aucune '),
              ]}
            />
            <Eye color="blue" />
          </CardClickable>
        ))
      )}
      <DetailsModal
        size="medium"
        selectedItem={selectedBusiness}
        setSelectedItem={setSelectedBusiness}
        DetailsComponent={CandidateBusinessModal}
      />
    </div>
  );
};

export default SkillsByJob;

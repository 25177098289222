import { Control } from 'react-hook-form';
import { z } from 'zod';

import { formSchema } from 'src/Containers/CandidateResume/CandidateResume.schema';

export interface BehaviorSkillsProps {
  control: Control<z.infer<typeof formSchema>>;
}

export const behaviorSkillsSuggestions = [
  'assiduité',
  'motivation',
  'dynamisme',
  'ponctualité',
  'fiabilité',
  'présentation',
];

import { WithLightTitle } from '@randstad-lean-mobile-factory/react-components-core';
import moment from 'moment';
import React from 'react';

import { useFetchCandidateBirthInfoFromURL } from 'src/Hooks/Candidates/useFetchCandidateBirthInfoFromURL';
import { toDate } from 'src/Utils/moment';

import { ifEmpty } from '../Visualization/Visualization.component';

import styles from './BirthInformations.module.scss';

const BirthInformations = () => {
  const candidateBirthInfo = useFetchCandidateBirthInfoFromURL().data;
  return (
    <div className={styles.grid}>
      <WithLightTitle title="né le">
        {`${
          candidateBirthInfo?.birthDate
            ? moment(toDate(candidateBirthInfo?.birthDate)).format('L')
            : 'non renseigné'
        } ${candidateBirthInfo?.birthCountry ? `- ${candidateBirthInfo?.birthCountry.label}` : ''}`}
      </WithLightTitle>
      <WithLightTitle title="à">
        {`${ifEmpty(candidateBirthInfo?.birthDepartment?.id)} - ${ifEmpty(
          candidateBirthInfo?.birthCity
        )} `}
      </WithLightTitle>
      <WithLightTitle title="nationalité">
        {ifEmpty(candidateBirthInfo?.nationality?.label?.toLocaleLowerCase())}
      </WithLightTitle>
      <WithLightTitle title="numéro de sécurité sociale">
        {ifEmpty(
          `${candidateBirthInfo?.socialSecurityId} ${candidateBirthInfo?.socialSecurityKey}`
        )}
      </WithLightTitle>
    </div>
  );
};
export default BirthInformations;

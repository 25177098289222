import { LogoutOptions } from '@auth0/auth0-react';

import { AxiosInterceptors } from './types';

let getAccessTokenSilently: (() => Promise<string>) | null = null;
let logout: ((options?: LogoutOptions | undefined) => void) | null = null;

export const security = {
  getAccessTokenSilently: () => getAccessTokenSilently,
  setAccessTokenSilently: (func: () => Promise<string>) => (getAccessTokenSilently = func),
  logout: () => logout,
  setLogout: (func: (options?: LogoutOptions | undefined) => void) => (logout = func),
};

export const securityInterceptors: AxiosInterceptors = {
  requestInterceptor: async config => {
    const token = await security.getAccessTokenSilently()?.();
    return {
      ...config,
      headers: {
        ...config.headers,
        Authorization: `Bearer ${token}`,
      },
    };
  },
};

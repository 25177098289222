import React from 'react';
import { Route, Router, Switch, useHistory, useParams } from 'react-router';

import { useFetchCandidateInterviewsIdAndStatus } from 'src/Hooks/Candidates/useFetchCandidateInterviewsIdAndStatus';
import { CandidateRecruitingRoute } from 'src/Services/Routing';

import { getNavigationStructure } from '../CandidateRecruitingProcess.types';

import styles from './CandidateRecruitingForm.module.scss';

const CandidateRecruitingForm = () => {
  const params = useParams<CandidateRecruitingRoute['routerParameters']>();
  const recruitingStatusQuery = useFetchCandidateInterviewsIdAndStatus(params?.candidateId ?? '');
  const history = useHistory();
  const firstSection = params?.firstSection as 'R1' | 'R2';

  const subRoute = getNavigationStructure(recruitingStatusQuery.data)[firstSection]?.routes.find(
    _ => _.linkTo === `./${params?.secondSection}`
  );

  const subRouteIndex = getNavigationStructure(recruitingStatusQuery.data)[
    firstSection
  ]?.routes.findIndex(_ => _.linkTo === `./${params?.secondSection}`);

  return (
    <div className={styles.container}>
      <div className={styles.routeNumber}>{`${subRouteIndex + 1}.`}</div>
      <div className={styles.formContainer}>
        <div className={styles.titleContainer}>
          <p>{subRoute?.name ?? 'Error'}</p>
        </div>
        <div className={styles.content}>
          <Router history={history}>
            <Switch>
              {Object.values(getNavigationStructure(recruitingStatusQuery.data)).map(node =>
                node.routes.map(route => {
                  const Component = route.component;
                  return (
                    <Route key={route.name} path={route.path} exact>
                      {Component && <Component />}
                    </Route>
                  );
                })
              )}
            </Switch>
          </Router>
        </div>
      </div>
    </div>
  );
};

export default CandidateRecruitingForm;

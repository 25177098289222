import {
  ServerErrorYellow,
  VidePasteque,
} from '@randstad-lean-mobile-factory/react-assets/dist/illustrations';
import {
  Button,
  CardCheckable,
  CardContent,
  Checkbox,
  Loader,
  ToggleMenu,
  ToggleMenuItem,
} from '@randstad-lean-mobile-factory/react-components-core';
import moment from 'moment';
import { Fragment, useMemo, useState } from 'react';
import { useController } from 'react-hook-form';
import { z } from 'zod';

import { ErrorMessage } from 'src/Components/ErrorMessage';
import { SORT_OPTIONS, SORT_ORDER } from 'src/Containers/CandidateFile/Experience/Experience.types';
import {
  getExperienceDuration,
  getExperienceSupportingLines,
  getUniqueExperienceQualifications,
  groupExperiences,
} from 'src/Containers/CandidateFile/Experience/utils';
import { useFetchCandidateExperiencesByCompany } from 'src/Hooks/Candidates/useFetchCandidateExperiencesByCompany';
import { useFetchCandidateSkills } from 'src/Hooks/Candidates/useFetchCandidateSkills';
import { pluralFormat } from 'src/Utils/pluralFormat';

import { formSchema } from '../CandidateResume.schema';

import styles from './Experiences.module.scss';
import { ExperiencesProps } from './Experiences.types';

export const Experiences = ({ candidateId, control, watch }: ExperiencesProps) => {
  const skillsQuery = useFetchCandidateSkills(candidateId, { retry: false });
  const experiencesQuery = useFetchCandidateExperiencesByCompany(candidateId, true);
  const isError = skillsQuery.isError || experiencesQuery.isError;

  const selectedQualificationIds = watch('qualifications');

  const selectedQualifications = selectedQualificationIds
    .map(qualificationId =>
      skillsQuery.data?.qualifications?.find(qualification => qualification.id === qualificationId)
    )
    .filter(Boolean);

  const [experiencesForAllQualifications, setExperiencesForAllQualifications] =
    useState<boolean>(true);

  const { field: experiencesField } = useController({
    name: 'experiences',
    control,
  });

  const [internal, setInternal] = useState(true);

  const groupedExperiences = useMemo(() => {
    let experiences = experiencesQuery.data?.experiences;
    if (!experiences) return;
    if (!experiencesForAllQualifications) {
      experiences = experiences.map(company => {
        const internalExperiences = company.experiencesByCompany.internalExperiences.filter(
          item =>
            item.qualification?.id && selectedQualificationIds.includes(item.qualification?.id)
        );
        const externalExperiences = company.experiencesByCompany.externalExperiences.filter(
          item =>
            item.qualification?.id && selectedQualificationIds.includes(item.qualification?.id)
        );

        return {
          ...company,
          experiencesByCompany: { internalExperiences, externalExperiences },
        };
      });
    }
    return {
      internal: groupExperiences(experiences, true, SORT_OPTIONS.YEAR, SORT_ORDER.DESCENDING),
      external: groupExperiences(experiences, false, SORT_OPTIONS.YEAR, SORT_ORDER.DESCENDING),
    };
  }, [
    experiencesForAllQualifications,
    experiencesQuery.data?.experiences,
    selectedQualificationIds,
  ]);

  const groups = useMemo(
    () => (internal ? groupedExperiences?.internal : groupedExperiences?.external),
    [groupedExperiences, internal]
  );

  return (
    <div className={styles.container}>
      <div className={styles.titleContainer}>
        sélectionnez les expériences à mettre en avant sur{' '}
        {experiencesForAllQualifications
          ? `l'ensemble des qualifications du talent`
          : selectedQualifications.length > 1
          ? 'les qualifications :'
          : 'la qualification :'}
        {!experiencesForAllQualifications && (
          <div className={styles.qualifications}>
            {selectedQualifications
              .map(qualification =>
                qualification.label
                  ? qualification.label.charAt(0) + qualification.label.slice(1).toLowerCase()
                  : ''
              )
              .filter(Boolean)
              .join(', ')}
          </div>
        )}
      </div>
      <ToggleMenu value={internal.toString()} onChange={value => setInternal(value === 'true')}>
        <ToggleMenuItem value="true">chez randstad</ToggleMenuItem>
        <ToggleMenuItem value="false" disabled={!groupedExperiences?.external.length}>
          en externe
        </ToggleMenuItem>
      </ToggleMenu>
      {selectedQualifications.length !== skillsQuery.data?.qualifications?.length && (
        <Checkbox
          checked={!experiencesForAllQualifications}
          onChange={() => setExperiencesForAllQualifications(!experiencesForAllQualifications)}
          label="visualiser uniquement les expériences sur les qualifications sélectionnées en amont"
        />
      )}
      <div className={styles.experiencesContainer}>
        {experiencesQuery.isLoading || skillsQuery.isLoading ? (
          <div className={styles.illustration}>
            <Loader size="large" />
            chargement des expériences en cours
          </div>
        ) : !groups ? (
          <div className={styles.illustration}>
            <ServerErrorYellow />
            désolé, une erreur s'est produite lors du chargement des expériences
            <Button
              size="medium"
              type="button"
              onClick={() => {
                if (!experiencesQuery.data) experiencesQuery.refetch();
                if (!skillsQuery.data) skillsQuery.refetch();
              }}
            >
              réessayer
            </Button>
          </div>
        ) : !groups.length ? (
          <div className={styles.illustration}>
            <VidePasteque />
            pas d'expérience via randstad
          </div>
        ) : isError ? (
          <>
            <ErrorMessage message="Une erreur est survenue dans la récupération des données" />
            <div>
              <Button
                size="medium"
                type="button"
                onClick={() => {
                  if (!experiencesQuery.data) experiencesQuery.refetch();
                  if (!skillsQuery.data) skillsQuery.refetch();
                }}
              >
                réessayer
              </Button>
            </div>
          </>
        ) : (
          groups.map(([label, companies]) => (
            <Fragment key={label}>
              {internal && (
                <div className={styles.groupLabel} data-theme="dark">
                  {label}
                </div>
              )}
              {companies.map((company, index) => (
                <CardCheckable
                  key={index}
                  color="beige"
                  checked={experiencesField.value.some(
                    exp => exp.companyName === company.companyName
                  )}
                  onChange={checked => {
                    if (checked) {
                      if (
                        !experiencesField.value.some(el => el.companyName === company.companyName)
                      ) {
                        const item: z.infer<typeof formSchema>['experiences'][number] = {
                          qualifications: getUniqueExperienceQualifications(company.experiences),
                          companyName: company.companyName ?? '',
                          firstDate:
                            company.experiences.length > 0
                              ? moment(company.experiences[0].startDate).format('L')
                              : '',
                          lastDate:
                            company.experiences.length > 0
                              ? moment(
                                  company.experiences[company.experiences.length - 1].endDate
                                ).format('L')
                              : '',
                          nbContracts: pluralFormat(company.experiences.length, 'contrat'),
                          duration: getExperienceDuration(company.experiences),
                          isInternalExperience: internal,
                        };
                        experiencesField.onChange([...experiencesField.value, item]);
                      }
                    } else {
                      experiencesField.onChange(
                        experiencesField.value.filter(
                          el => !(el.companyName === company.companyName)
                        )
                      );
                    }
                  }}
                >
                  <CardContent
                    title={company.companyName}
                    supportingLines={getExperienceSupportingLines(company.experiences)}
                  />
                </CardCheckable>
              ))}
            </Fragment>
          ))
        )}
      </div>
    </div>
  );
};

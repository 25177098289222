import { SortDescending } from '@randstad-lean-mobile-factory/react-assets/dist/icons';
import {
  ServerErrorYellow,
  VidePasteque,
} from '@randstad-lean-mobile-factory/react-assets/dist/illustrations';
import {
  Button,
  Card,
  CardContent,
  Loader,
} from '@randstad-lean-mobile-factory/react-components-core';
import moment from 'moment';
import { Fragment, useMemo, useState } from 'react';
import { useParams } from 'react-router';

import { useFetchCandidateDetails } from 'src/Hooks/Candidates/useFetchCandidateDetails';
import { useFetchCandidateExperiencesByCompany } from 'src/Hooks/Candidates/useFetchCandidateExperiencesByCompany';

import styles from './Experience.module.scss';
import { ExperienceProps, SORT_OPTIONS, SORT_ORDER } from './Experience.types';
import { SortOptions } from './SortOptions';
import { getExperienceSupportingLines, groupExperiences } from './utils';

const Experience = ({ internal }: ExperienceProps) => {
  const [sortOption, setSortOption] = useState<SORT_OPTIONS>(SORT_OPTIONS.NAME);
  const [sortOrder, setSortOrder] = useState<SORT_ORDER>(SORT_ORDER.ASCENDING);

  const { candidateId } = useParams<{ candidateId: string }>();
  const candidateDetails = useFetchCandidateDetails(candidateId);
  const { data, isLoading } = useFetchCandidateExperiencesByCompany(candidateId, true);

  const groups = useMemo(
    () => data?.experiences && groupExperiences(data.experiences, internal, sortOption, sortOrder),
    [data?.experiences, internal, sortOption, sortOrder]
  );

  const title = useMemo(() => {
    const allExperiences =
      groups?.flatMap(([_, companies]) => companies.flatMap(company => company.experiences)) ?? [];

    const startDate = moment.min(
      allExperiences
        .map(experience => experience.startDate)
        .filter(Boolean)
        .map(date => moment(date))
    );
    const endDate = moment.max(
      allExperiences
        .map(experience => experience.endDate)
        .filter(Boolean)
        .map(date => moment(date))
    );

    if (!internal) return `expérience externe de ${startDate.year()} à ${endDate.year()}`;

    if (!candidateDetails.data?.nbHoursWorked)
      return `expérience via randstad de ${startDate.year()} à ${endDate.year()}`;

    return `${
      candidateDetails.data.nbHoursWorked
    } heures totales via randstad de ${startDate.year()} à ${endDate.year()}`;
  }, [candidateDetails.data?.nbHoursWorked, groups, internal]);

  return (
    <>
      {isLoading ? (
        <div className={styles.illustration}>
          <Loader size="large" />
          chargement des expériences en cours
        </div>
      ) : !groups ? (
        <div className={styles.illustration}>
          <ServerErrorYellow />
          désolé, une erreur s'est produite lors du chargement des expériences
        </div>
      ) : !groups.length ? (
        <div className={styles.illustration}>
          <VidePasteque />
          {internal ? "pas d'expérience via randstad" : "pas d'expérience externe"}
        </div>
      ) : (
        <>
          <div className={styles.toolbar}>
            {title}
            <SortOptions
              trigger={
                <Button variant="tertiary" size="small">
                  <SortDescending />
                </Button>
              }
              selectedSortOption={sortOption}
              setSelectedSortOption={setSortOption}
              selectedSortOrder={sortOrder}
              setSelectedSortOrder={setSortOrder}
            />
          </div>
          <div className={styles.experiences}>
            {groups.map(([label, companies]) => (
              <Fragment key={label}>
                {internal && sortOption !== SORT_OPTIONS.HOURS && (
                  <div className={styles.groupLabel} data-theme="dark">
                    {label}
                  </div>
                )}
                {companies.map((company, index) => (
                  <Card key={index} color="white">
                    <CardContent
                      title={company.companyName}
                      supportingLines={getExperienceSupportingLines(company.experiences)}
                    />
                  </Card>
                ))}
              </Fragment>
            ))}
          </div>
        </>
      )}
    </>
  );
};

export default Experience;

import { useAuth0 } from '@auth0/auth0-react';
import { ToastContainer } from '@randstad-lean-mobile-factory/react-components-core';
import { useEffect } from 'react';
import { Route, Router, useLocation } from 'react-router-dom';

import history from 'src/Utils/history';

import { AppRoot } from './Containers/AppRoot/AppRoot.component';
import CandidateResume from './Containers/CandidateResume';
import { Login } from './Containers/Login';
import { useShowLoginScreen } from './Containers/Login/Login.types';
import AvailabilityModal from './Containers/Modals/AvailabilityModal';
import CACESModal from './Containers/Modals/CACESModal';
import CompanyModal from './Containers/Modals/CompanyModal';
import CriteriaLanguageModal from './Containers/Modals/CriteriaLanguageModal';
import DiplomaModal from './Containers/Modals/DiplomaModal';
import DrivingLicenceModal from './Containers/Modals/DrivingLicenceModal';
import HabilitationModal from './Containers/Modals/HabilitationModal';
import R1ReassignCGCModal from './Containers/Modals/InterviewsModals/R1ReassignCGCModal';
import KeywordModal from './Containers/Modals/KeywordModal';
import LocationModal from './Containers/Modals/LocationModal';
import PotentialPositionModal from './Containers/Modals/PotentialPositionModal';
import QualificationModal from './Containers/Modals/QualificationModal';
import ServiceModal from './Containers/Modals/ServiceModal';
import SkillModal from './Containers/Modals/SkillModal';
import useInitializeAnalytics from './Hooks/Analytics/useInitializeAnalytics';
import { LocationState, ModalRoute } from './Hooks/Modals';
import { setupAPI } from './Services/API/APIConfigurator';
import { security } from './Services/API/Interceptors/security';

const modalRouteMap: ModalRoute[] = [
  { route: 'availability', component: AvailabilityModal },
  { route: 'qualification', component: QualificationModal },
  { route: 'skill', component: SkillModal },
  { route: 'location', component: LocationModal },
  { route: 'diploma', component: DiplomaModal },
  { route: 'driving-licence', component: DrivingLicenceModal },
  { route: 'language', component: CriteriaLanguageModal },
  { route: 'habilitation', component: HabilitationModal },
  { route: 'company', component: CompanyModal },
  { route: 'service', component: ServiceModal },
  { route: 'potential-position', component: PotentialPositionModal },
  { route: 'reassignCgc/:id', component: R1ReassignCGCModal },
  { route: 'keyword', component: KeywordModal },
  { route: 'caces', component: CACESModal },
  {
    route: 'candidate/:candidateId/resume',
    component: CandidateResume,
  },
];

const Root = () => {
  const showLoginScreen = useShowLoginScreen();
  const location = useLocation<LocationState>();
  const locationState = location.state;

  if (showLoginScreen) return <Login />;

  return (
    <>
      <AppRoot />
      {modalRouteMap.map(modalInfo => (
        <Route
          key={modalInfo.route}
          path={`/${modalInfo.route}`}
          children={<modalInfo.component modalParameters={locationState?.modalParameters} />}
        />
      ))}
      <ToastContainer position="bottom-right" />
    </>
  );
};

export const App = () => {
  const { getAccessTokenSilently, logout } = useAuth0();
  security.setAccessTokenSilently(getAccessTokenSilently);
  security.setLogout(logout);
  useEffect(() => setupAPI(), []);
  useInitializeAnalytics();
  return (
    <div className="App">
      <Router history={history}>
        <Root />
      </Router>
    </div>
  );
};
